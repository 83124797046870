import { RequestOptions } from "./types/Request";
import { useState } from "react";
import { BASE_URL } from "../utils/http";

export const useChangeNewsStatus = (statusChange: RequestOptions) => {
  const [loading, setLoading] = useState(false);

  const changeStatus = (newId?: string, newStatus?: string) => {
    setLoading(true);
    BASE_URL.put(`/news/${newId}`, {
      newsStatus: newStatus === "public" ? "draft" : "public",
    })
      .then((res) => {
        statusChange.onSuccess && statusChange.onSuccess(res);
      })
      .catch((e: any) => {
        statusChange.onError && statusChange.onError(e.response.data);
      });
    setLoading(false);
  };

  return { changeStatus, loading };
};
