export const useTranslate = () => {
  const translateError = (error: any) => {
    switch (error) {
      //aws errors
      case "You do not have sufficient access to perform this action.": {
        return "No tienes suficientes accesos para realizar esta acción.";
      }
      case "The request processing has failed because of an unknown error, exception or failure.": {
        return "El procesamiento de la solicitud ha fallado debido a un error desconocido, una excepción o una falla. ";
      }
      case "The action or operation requested is invalid. Verify that the action is typed correctly.": {
        return "La acción u operación solicitada no es válida. Verifique que la acción esté escrita correctamente.";
      }
      case "Parameters that must not be used together were used together.": {
        return "Los parámetros que no deben usarse juntos se usaron juntos.";
      }
      case "An invalid or out-of-range value was supplied for the input parameter.": {
        return "Se proporcionó un valor no válido o fuera de rango para el parámetro de entrada.";
      }
      case "You do not have permission to perform this action.": {
        return "No tienes permiso para realizar esta acción.";
      }
      case "The request has failed due to a temporary failure of the server.": {
        return "La solicitud ha fallado debido a una falla temporal del servidor.";
      }
      //login
      case "The access token is invalid or expired.": {
        return "El token de acceso es inválido o expiró. Iniciá sesión nuevamente para continuar";
      }
      case "Incorrect username or password.": {
        return "Usuario o contraseña incorrecto";
      }
      case "A new password is required": {
        return "Una nueva contraseña es requerida.";
      }
      case "Password attempts exceeded": {
        return "Cantidad de intentos de acceso excedidos.";
      }
      //register
      case "Username already exists": {
        return "El nombre de usuario ya existe.";
      }
      case "More than 1 user found with the same email": {
        return "Se encontró 1 usuario con el mismo email.";
      }
      //news erros
      case "File not found!": {
        return "No se encontró el archivo!";
      }
      case "Invalid section id.": {
        return "ID de Categoría inválido.";
      }
      //general
      case "Invalid Password": {
        return "Contraseña incorrecta";
      }
      case "Email not found!": {
        return "El correo electrónico no fue encontrado";
      }
      case "Forbidden resource": {
        return "Recurso prohibido.";
      }
      case "File size is too big": {
        return "El tamaño del archivo es demasiado grande.";
      }
      case "File type is not supported": {
        return "El tipo de archivo no es compatible.";
      }
      case "Unauthorized": {
        return "Sin autorización.";
      }
      default: {
        return "Error";
      }
    }
  };

  const translateChip = (status: string): string => {
    switch (status) {
      case "public":
        return "Pública";
      case "draft":
        return "Borrador";
      default:
        return "";
    }
  };
  return { translateError, translateChip };
};
