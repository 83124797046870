import { RequestOptions } from "./types/Request";
import { useEffect, useState } from "react";
import { BASE_URL } from "../utils/http";

interface ICategory {
  sectionName: string;
}

interface AddSection {
  sectionName: string;
  description: string;
}

export interface Category {
  id?: string;
  sectionName: string;
  description: string;
  newsCount?: number;
  hidden: boolean;
}

export const useGetCategories = (optionsCategories: RequestOptions) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Category[]>([]);

  const categoriesFilters = async (filter: ICategory) => {
    setLoading(true);
    BASE_URL.get(`/section?${filter.sectionName && `s=${filter.sectionName}`}`)
      .then((resCategoriesFilters) => {
        optionsCategories.onSuccess &&
          optionsCategories.onSuccess(resCategoriesFilters.data.Items);
        setLoading(false);
        setData(resCategoriesFilters.data.Items);
      })
      .catch((e: any) => {
        optionsCategories.onError && optionsCategories.onError(e.response.data);
        setLoading(false);
      });
  };

  const addSection = (section: AddSection) => {
    setLoading(true);
    BASE_URL.post("/section", section)
      .then(() => {
        setData([
          ...data,
          {
            ...section,
            id: Math.random().toString(),
            newsCount: 0,
            hidden: true,
          },
        ]);
        optionsCategories.onSuccess &&
          optionsCategories.onSuccess([
            ...data,
            { ...section, id: Math.random().toString(), newsCount: 0 },
          ]); //Avoid refetching on Add
      })
      .catch((e: any) => {
        optionsCategories.onError && optionsCategories.onError(e.response.data);
      });
    setLoading(false);
  };

  const editSection = (section: Category) => {
    setLoading(true);
    const { id, sectionName, description, hidden } = section;
    BASE_URL.patch(`/section/${id}`, { sectionName, description, hidden })
      .then(() => {
        setData(data.map((cat) => (cat.id === id ? section : cat)));
        optionsCategories.onSuccess &&
          optionsCategories.onSuccess(
            data.map((cat) => (cat.id === id ? section : cat))
          ); //Avoid refetching on Edit
      })
      .catch((e: any) => {
        optionsCategories.onError && optionsCategories.onError(e.response.data);
      });
    setLoading(false);
  };

  const deleteCategory = async (uuid: string) => {
    setLoading(true);
    BASE_URL.delete(`/section/${uuid}`)
      .then(() => {
        setData(data.filter((cat) => cat.id !== uuid));
        optionsCategories.onSuccess &&
          optionsCategories.onSuccess(data.filter((cat) => cat.id !== uuid));
        setLoading(false);
      })
      .catch((e: any) => {
        optionsCategories.onError && optionsCategories.onError(e.response.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    categoriesFilters({ sectionName: "" });
  }, []);
  return {
    data,
    categoriesFilters,
    addSection,
    editSection,
    loading,
    deleteCategory,
  };
};
