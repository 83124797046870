import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth.slice";
import { firstLoginSlice } from "./firstLogin.slice";
import { forgotPasswordSlice } from "./forgotPassword.slice";
import { modalSlice } from "./modal.slice";
import { toastSlice } from "../components/common/Toast/toast.slice";
import { newsSlice } from "./news.slice";
import { thingsToPublishSlice } from "./thingsToPublish.slice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    firstLogin: firstLoginSlice.reducer,
    forgotPassword: forgotPasswordSlice.reducer,
    modal: modalSlice.reducer,
    toast: toastSlice.reducer,
    news: newsSlice.reducer,
    thingsToPublish: thingsToPublishSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["modal/openModal"],
        ignoredPaths: [
          "modal.modal.props.editSection",
          "modal.modal.props.addSection",
          "modal.modal.props.addSponsor",
          "modal.modal.props.addEditor",
          "modal.modal.props.action",
        ],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
