import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import LoginContainer from "components/screens/Login/LoginContainer";
import WriteNews from "components/screens/WriteNews/WriteNews";
import HomeNews from "components/screens/HomeNews/HomeNews";
import Categories from "components/screens/Categories/Categories";
import FirstLogin from "components/screens/FirstLogin/FirstLogin";
import Sponsor from "components/screens/Sponsor/Sponsor";
import Editors from "components/screens/Editors/Editors";
import EditNews from "components/screens/WriteNews/EditNews";
import ForgotPassword from "components/screens/ForgotPassword/ForgotPassword";
import ConfirmationPasswordContainer from "components/screens/ForgotPassword/ConfirmationPasswordContainer";
import NotFound from "components/screens/NotFound/NotFound";
import MyData from "components/screens/MyData/MyData";
import NewsPreview from "components/screens/NewsPreview/NewsPreview";
import StatsScreen from "components/screens/Stats/StatsScreen";
import StatsUsersScreen from "components/screens/Stats/StatsUsersScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthRoute />,
    children: [
      {
        path: "/",
        element: <HomeNews />,
      },
      {
        path: "/categorias",
        element: <Categories />,
      },
      {
        path: "/estadisticas",
        element: <StatsScreen />,
      },
      {
        path: "/estadisticas-usuarios",
        element: <StatsUsersScreen />,
      },
      {
        path: "/editar",
        element: <EditNews />,
      },
      {
        path: "/escribir",
        element: <WriteNews />,
      },
      {
        path: "/vista-previa",
        element: <NewsPreview />,
      },
      {
        path: "/editores",
        element: <Editors />,
      },
      {
        path: "/patrocinadores",
        element: <Sponsor />,
      },
      {
        path: "/mis-datos",
        element: <MyData />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginContainer />,
  },
  {
    path: "/primer-login",
    element: <FirstLogin />,
  },
  {
    path: "/recuperar",
    element: <ForgotPassword />,
  },
  {
    path: "/confirmar",
    element: <ConfirmationPasswordContainer />,
  },
]);

const Layout = () => {
  return <RouterProvider router={router} />;
};

export default Layout;
