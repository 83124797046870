type PeriodType = {
  value: number;
  title: string;
}[];

const currentTime = Date.now();

export const periods: PeriodType = [
  {
    value: currentTime - 24 * 60 * 60 * 1000,
    title: "24 horas",
  },
  {
    value: currentTime - 48 * 60 * 60 * 1000,
    title: "48 horas",
  },
  {
    value: currentTime - 5 * 24 * 60 * 60 * 1000,
    title: "5 días",
  },
  {
    value: currentTime - 7 * 24 * 60 * 60 * 1000,
    title: "7 días",
  },
  {
    value: currentTime - 15 * 24 * 60 * 60 * 1000,
    title: "15 días",
  },
  {
    value: currentTime - 30 * 24 * 60 * 60 * 1000,
    title: "30 días",
  },
  {
    value: currentTime - 3 * 30 * 24 * 60 * 60 * 1000,
    title: "3 meses",
  },
  // {
  //   value: currentTime - 6 * 30 * 24 * 60 * 60 * 1000,
  //   title: "6 meses",
  // },
];
