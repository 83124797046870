import { Box } from "@mui/material";
import { ComponentType, ReactNode } from "react";
import NavBar from "components/common/NavBar/NavBar";
import SideBar from "../components/common/SideBar/SideBar";

type LayoutProps = {
  children: ReactNode;
};

const NavbarContainer = ({ children }: LayoutProps) => {
  return (
    <>
      <NavBar userName="Mis Datos" />
      <Box height="calc(100% - 68px - 56px)" marginTop="56px" marginX="72px">
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "24px",
          }}
        >
          <SideBar />
          <Box flex={5} height="100%">
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const withNavbars = (WrappedComponent: ComponentType<any>) => {
  return (props: any) => (
    <NavbarContainer>
      <WrappedComponent {...props} />
    </NavbarContainer>
  );
};

export default withNavbars;
