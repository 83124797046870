import { RequestOptions } from "./types/Request";
import { useState } from "react";
import { BASE_URL } from "../utils/http";

interface Email {
  email: string;
}

interface ConfirmPassword {
  email: string;
  confirmationCode?: string;
  newPassword: string;
}

export const useAdminForgotPassword = (options: RequestOptions) => {
  const [loading, setLoading] = useState(false);
  const forgotPassword = async (emailData: Email) => {
    setLoading(true);
    try {
      await BASE_URL.post(`/admin/forgot-password`, emailData)
        .then((res: any) => {
          options.onSuccess && options.onSuccess(res.data);
        })
        .catch((e: any) => {
          options.onError && options.onError(e.response.data);
        });
    } catch (e: any) {
      options.onError && options.onError(e.response.data);
    } finally {
      setLoading(false);
    }
  };

  const confirmPassword = async (data: ConfirmPassword) => {
    setLoading(true);
    try {
      await BASE_URL.post("/admin/confirm-password", data)
        .then((res: any) => {
          options.onSuccess && options.onSuccess(res.data);
        })
        .catch((e: any) => {
          options.onError && options.onError(e.response.data);
        });
    } catch (e: any) {
      options.onError && options.onError(e.response.data);
    } finally {
      setLoading(false);
    }
  };

  return { forgotPassword, loading, confirmPassword };
};
