import {
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import AddButton from "../AddButton/AddButton";
import { theme } from "styles/Theme";

const ConfirmationModal = ({
  onClose,
  action,
}: {
  onClose: () => void;
  action?: () => void;
}) => {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth={"sm"}
      PaperProps={{
        style: {
          borderRadius: 8,
        },
      }}
    >
      <DialogTitle
        color={"black"}
        variant="h5"
        sx={{ fontWeight: "bold", alignContent: "center" }}
      >
        ¿Seguro quieres eliminar?
      </DialogTitle>
      <DialogActions>
        <Grid container justifyContent={"space-evenly"} sx={{ m: 1.5 }}>
          <AddButton
            onClick={onClose}
            style={{
              backgroundColor: theme.palette.grey[200],
              color: theme.palette.grey[900],
              "&:hover": {
                backgroundColor: theme.palette.grey[300],
                boxShadow: "none",
              },
            }}
            size="small"
          >
            <Typography variant={"h6"} fontWeight="bold">
              Cancelar
            </Typography>
          </AddButton>
          <AddButton
            onClick={() => {
              action && action();
              onClose();
            }}
            color="error"
            size={"small"}
          >
            <Typography variant={"h6"} fontWeight="bold">
              Aceptar
            </Typography>
          </AddButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
