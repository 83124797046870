import { Card, CardContent, Grid, Typography } from "@mui/material";
import CardRow from "../CardRow/CardRow";
import { Options } from "types/News";
import Pagination from "../Pagination/Pagination";

interface Props {
  data: Array<any>;
  showDate?: boolean;
  showMenu?: boolean;
  showCount?: boolean;
  menuOptions: Options[];
  selectedItem?: (a: any) => void;
  pagination?: {
    onNext: () => void;
    onPrevious: () => void;
    lastPage?:boolean;
  };
}

interface Row {
  id: string;
  title: string;
  sectionName: string;
  mail: string;
  name: string;
  newsStatus: string;
  createdAt: string;
  action: any;
  linkedSection: string;
  newsCount: number;
}

const TableAdmin = ({
  data,
  showDate = true,
  showMenu = true,
  showCount = false,
  menuOptions,
  selectedItem,
  pagination,
}: Props) => {
  return (
    <Card
      sx={{
        borderRadius: "0px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        overflow: "auto",
        height: "100%",
      }}
      elevation={0}
    >
      <CardContent>
        {data.length > 0 ? (
          <>
            {data.map((item: Row, index: number) => {
              return (
                <Grid key={index} sx={{ m: 2 }}>
                  <CardRow
                    data={item}
                    key={item.id}
                    id={item.id}
                    title={
                      item.title
                        ? item.title
                        : item.sectionName || item.name || item.mail
                    }
                    showDate={showDate}
                    date={item.createdAt}
                    onClickOptions={() => selectedItem && selectedItem(item)}
                    section={item.linkedSection}
                    showCount={showCount}
                    showMenu={showMenu}
                    count={item.newsCount}
                    menuOptions={menuOptions}
                    newsStatus={item.newsStatus}
                  />
                </Grid>
              );
            })}
            {pagination && (
              <Pagination
                onNext={pagination.onNext}
                onPrevious={pagination.onPrevious}
                lastPage={pagination.lastPage}
              />
            )}
          </>
        ) : (
          <Grid sx={{ m: 2 }}>
            <Typography justifyContent={"center"}>
              No se han encontrado resultados
            </Typography>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default TableAdmin;
