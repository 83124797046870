import { RequestOptions } from "./types/Request";
import { useState } from "react";
import { BASE_URL, BASE_URL_FILES } from "utils/http";
import axios from "axios";

export const useUploadNewsImage = (image: RequestOptions) => {
  const [loading, setLoading] = useState(false);

  const loadImage = async (name: string, file: any) => {
    setLoading(true);
    await BASE_URL.post('/news/pressigned', { name }).then(async (res: any) => {
      const location = res.data.publicUrl;
      await axios.put(res.data.postUrl, file).then(() => {
        image.onSuccess && image.onSuccess(location);
      })
        .catch((e: any) => {
          image.onError && image.onError(e);
        });
    })
      .catch((e: any) => {
        image.onError && image.onError(e);
      });
    setLoading(false);
  }

  return { loadImage, loading };
};
