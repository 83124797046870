import { Stats } from "hooks/useGetStats";
import { TableComponents, TableVirtuoso } from "react-virtuoso";
import { forwardRef, Fragment, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

type Keys =
  | "name"
  | "email"
  | "phone_number"
  | "company"
  | "role"
  | "timestamp";
type Columns = {
  label: string;
  dataKey: Keys;
  news?: boolean;
}[];

const columns: Columns = [
  {
    label: "Hora",
    dataKey: "timestamp",
  },
  {
    label: "Nombre",
    dataKey: "name",
  },
  {
    label: "Email",
    dataKey: "email",
  },
  {
    label: "Teléfono",
    dataKey: "phone_number",
  },
  {
    label: "Empresa",
    dataKey: "company",
  },
  {
    label: "Cargo",
    dataKey: "role",
  },
];

const VirtuosoTableComponents: TableComponents<Stats> = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: (props) => <TableRow {...props} />,
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};
type Sorting = {
  field: Keys;
  ascending: boolean;
};
const FixedHeaderContent = ({
  sorting,
  setSorting,
}: {
  sorting: Sorting;
  setSorting: (s: Sorting) => void;
}) => {
  return (
    <TableRow sx={{ backgroundColor: "#EDEDED" }}>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          sx={{
            cursor: "pointer",
            fontWeight: sorting.field === column.dataKey ? "bold" : "normal",
          }}
          variant="head"
          onClick={() =>
            setSorting({ field: column.dataKey, ascending: !sorting.ascending })
          }
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
};
const getLocalTime = (epochTimestamp: number) => {
  const date = new Date(epochTimestamp);
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

const rowContent = (_index: number, row: Stats) => {
  return (
    <Fragment>
      {columns.map((column) => (
        <TableCell key={column.dataKey}>
          {column.dataKey === "timestamp"
            ? getLocalTime(+row[column.dataKey])
            : row[column.dataKey]}
        </TableCell>
      ))}
    </Fragment>
  );
};

export const StatsTable = ({ data }: { data: Stats[] }) => {
  const [sorting, setSorting] = useState<Sorting>({
    field: "timestamp",
    ascending: false,
  });

  return (
    <Box height="100%">
      <TableVirtuoso
        style={{ boxShadow: "none", border: "1px solid #EDEDED" }}
        data={
          sorting.ascending
            ? data.sort((a, b) =>
                a[sorting.field].localeCompare(b[sorting.field])
              )
            : data
                .sort((a, b) =>
                  a[sorting.field].localeCompare(b[sorting.field])
                )
                .reverse()
        }
        components={VirtuosoTableComponents}
        fixedHeaderContent={() => (
          <FixedHeaderContent sorting={sorting} setSorting={setSorting} />
        )}
        itemContent={rowContent}
      />
    </Box>
  );
};
