import { useEffect, useState } from "react";
import { BASE_URL } from "../utils/http";

export const useGetRoutes = () => {
  const [routes, setRoutes] = useState<string[]>([]);

  const getRoutes = () => {
    BASE_URL.get("/stats/path").then((res) => {
      setRoutes(res.data.Items.map((i: string) => decodeURI(i)));
    });
  };
  useEffect(() => {
    getRoutes();
  }, []);

  return { routes };
};
