import { Box, Typography } from "@mui/material";
import { Count } from "hooks/useGetUserStats";
import { theme } from "styles/Theme";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import React from "react";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
};

export const BarChart = ({ dataRoutes }: { dataRoutes: Count }) => {
  const data = {
    labels: Object.keys(dataRoutes),
    datasets: [
      {
        data: Object.values(dataRoutes),
        backgroundColor: theme.palette.primary.main,
      },
    ],
  };

  return (
    <Box height="100%">
      {Object.keys(dataRoutes).length ? (
        <Bar options={options} data={data} />
      ) : (
        <Typography>No hay datos del usuario</Typography>
      )}
    </Box>
  );
};
