import { Add } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import AddButton from "components/common/AddButton/AddButton";
import { useAppDispatch } from "redux/hooks";
import { ModalType, openModal } from "redux/modal.slice";

const SponsorFilterBar = ({
  title,
  addSponsor,
}: {
  title: string;
  search?: (s: string) => void;
  sendSearch?: any;
  addSponsor: (s: any) => void;
}) => {
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: "8px",
        backgroundColor: "white",
        padding: 2,
      }}
    >
      <Grid container direction={"column"}>
        <Grid item container justifyContent={"space-between"}>
          <Grid item display="flex" alignItems="center">
            <Typography component={"div"} fontWeight={"bold"} variant="h5">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={"auto"} container alignItems={"center"}>
            <AddButton
              startIcon={<Add />}
              onClick={() =>
                dispatch(
                  openModal({
                    type: ModalType.ADD_SPONSOR,
                    props: {
                      action: addSponsor,
                    },
                  })
                )
              }
              size="large"
            >
              <Typography fontWeight={"bold"}>
                Añadir nuevo patrocinador
              </Typography>
            </AddButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SponsorFilterBar;
