import { RequestOptions } from "./types/Request";
import { useEffect, useState } from "react";
import { BASE_URL } from "utils/http";
import { ISponsor } from "../types/News";

export const useGetSponsors = (optionsSponsors: RequestOptions) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ISponsor[]>([]);

  const getSponsors = async () => {
    setLoading(true);
    BASE_URL.get(`/sponsor`)
      .then((res: any) => {
        optionsSponsors.onSuccess && optionsSponsors.onSuccess(res.data.Items);
        setData(res.data.Items);
        setLoading(false);
      })
      .catch((e: any) => {
        optionsSponsors.onError && optionsSponsors.onError(e.response.data);
        setLoading(false);
      });
  };

  const createSponsors = (sponsor: ISponsor) => {
    setLoading(true);
    BASE_URL.post(`/sponsor`, sponsor)
      .then(() => {
        // setData([{ ...sponsor, id: "1", sponsorOrder: data.length }, ...data]);
        getSponsors().then(() => {
          optionsSponsors.onSuccess && optionsSponsors.onSuccess(data);
          setLoading(false);
        });
      })
      .catch((e: any) => {
        optionsSponsors.onError && optionsSponsors.onError(e.response.data);
        setLoading(false);
      });
  };

  const editSponsors = (sponsor: ISponsor) => {
    setLoading(true);
    const { id, name, url, featureImageUrl } = sponsor;
    BASE_URL.put(`/sponsor/${id}`, { name, url, featureImageUrl })
      .then(() => {
        const newData = data.map((d) => {
          if (d.id === id) {
            return sponsor;
          } else {
            return d;
          }
        });
        setData(newData);
        optionsSponsors.onSuccess && optionsSponsors.onSuccess(newData);
        setLoading(false);
      })
      .catch((e: any) => {
        optionsSponsors.onError && optionsSponsors.onError(e.response.data);
        setLoading(false);
      });
  };

  const deleteSponsors = (id: string) => {
    setLoading(true);
    BASE_URL.delete(`/sponsor/${id}`)
      .then(() => {
        setData(data.filter((sponsor) => sponsor.id !== id));
        optionsSponsors.onSuccess &&
          optionsSponsors.onSuccess(
            data.filter((sponsor) => sponsor.id !== id)
          );
        setLoading(false);
      })
      .catch((e: any) => {
        optionsSponsors.onError && optionsSponsors.onError(e.response.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    getSponsors();
  }, []);
  return {
    getSponsors,
    loading,
    createSponsors,
    editSponsors,
    deleteSponsors,
    data,
  };
};
