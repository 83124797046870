import { Box } from "@mui/material";
import LogoImage from "assets/logo.png";
import { SideBarOptions } from "types/SideBarTypes";
import { Image } from "../Image";
import SideBarItem from "./SideBarItem";
import {
  DonutLarge,
  FeedOutlined,
  FolderOpen,
  PeopleOutline,
  TrendingUp,
} from "@mui/icons-material";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import { useFeatureFlags } from "../context/FeatureFlagsContext";

const SideBar = () => {
  const featureFlag = useFeatureFlags();
  const sidebarOptions: SideBarOptions[] = [
    {
      key: "noticias",
      path: "/",
      title: "Noticias",
      icon: <FeedOutlined />,
      visible: true,
    },
    {
      key: "categorias",
      path: "/categorias",
      title: "Categorias",
      icon: <FolderOpen />,
      visible: true,
    },
    {
      key: "estadisticas",
      path: "/estadisticas",
      title: "Estadísticas por Ruta",
      icon: <TrendingUp />,
      visible: featureFlag?.statsRoutes,
    },
    {
      key: "estadisticas-usuarios",
      path: "/estadisticas-usuarios",
      title: "Estadísticas por Usuario",
      icon: <DonutLarge />,
      visible: featureFlag?.statsUsers,
    },
    {
      key: "editores",
      path: "/editores",
      title: "Editores",
      icon: <PeopleOutline />,
      visible: featureFlag?.editors,
    },
    {
      key: "patrocinadores",
      path: "/patrocinadores",
      title: "Patrocinadores",
      icon: <BeenhereOutlinedIcon />,
      visible: true,
    },
  ];

  return (
    <Box sx={{ height: "100%", flex: 1 }}>
      <Box
        sx={{
          background: "white",
          width: "100%",
          height: "100%",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              src={LogoImage}
              alt="Noticias Industriales"
              style={{ marginTop: 8, width: "70%", height: "auto" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              padding: 4,
            }}
          >
            {sidebarOptions.map((option) => {
              if (option.visible) {
                return <SideBarItem key={option.key} option={option} />;
              }
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SideBar;
