import { useAppDispatch } from "redux/hooks";
import {
  generateErrorToast,
  generateInfoToast,
  generateSuccessToast,
} from "./toast.slice";

export const useToast = () => {
  const dispatch = useAppDispatch();

  const successToast = (text: string) => {
    dispatch(generateSuccessToast(text));
  };

  const errorToast = (text: string) => {
    dispatch(generateErrorToast(text));
  };

  const infoToast = (text: string) => {
    dispatch(generateInfoToast(text));
  };

  return { successToast, errorToast, infoToast };
};
