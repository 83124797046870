import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export enum ModalType {
  ADD_CATEGORY = "ADD_CATEGORY",
  CONFIRMATION_MODAL = "CONFIRMATION_MODAL",
  ADD_SPONSOR = "ADD_SPONSOR",
  ADD_EDITOR = "ADD_EDITOR",
  CONFIRMATION_PUBLISHED = "CONFIRMATION_PUBLISHED",
}

interface ModalPayload {
  type?: ModalType;
  props?: {
    [key: string]: any;
  };
}

interface ModalState {
  modal?: ModalPayload;
}

const initialState: ModalState = {};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<ModalPayload>) => {
      state.modal = action.payload;
    },
    closeModal: (state) => {
      state.modal = undefined;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
