import { Navigate } from "react-router-dom";
import Login from "./Login";
import { useAppSelector } from "redux/hooks";

const LoginContainer = () => {
  const authenticated = useAppSelector((state) => state.auth.isAuthenticated);
  return authenticated ? <Navigate to="/" replace /> : <Login />;
};

export default LoginContainer;
