import { createContext, useContext } from "react";

interface FeatureFlags {
  [key: string]: boolean;
}
const featureFlags: FeatureFlags = {
  statsUsers: true,
  statsRoutes: false,
  addCategories: false,
  editors: false,
  myData: true,
  sponsorsMenu: true,
  editCategories: false,
};
const FeatureFlagsContext = createContext(featureFlags);

export const FeatureFlags = (props: any) => (
  <FeatureFlagsContext.Provider value={featureFlags} {...props} />
);
export const useFeatureFlags = () => {
  return useContext(FeatureFlagsContext);
};
