import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const toastSlice = createSlice({
  name: "toast",
  initialState: {},
  reducers: {
    generateSuccessToast: (state, action: PayloadAction<string>) => {
      toast.success(action.payload);
    },
    generateErrorToast: (state, action: PayloadAction<string>) => {
      toast.error(action.payload);
    },
    generateInfoToast: (state, action: PayloadAction<string>) => {
      toast.info(action.payload);
    },
  },
});

export const { generateInfoToast, generateSuccessToast, generateErrorToast } =
  toastSlice.actions;

export default toastSlice.reducer;
