import { Box, CircularProgress, Grid } from "@mui/material";
import TableAdmin from "components/common/TableAdmin/TableAdmin";
import withNavbars from "hoc/withNavbar";
import { useState, SyntheticEvent } from "react";
import { theme } from "styles/Theme";
import { DeleteOutlineSharp, EditOutlined } from "@mui/icons-material";
import EditorsFilterBar from "./EditorsFilterBar";
import { useGetEditors } from "hooks/useGetEditors";
import { useAppDispatch } from "redux/hooks";
import { useToast } from "components/common/Toast/useToast";
import { Options } from "types/News";
import { useTranslate } from "hooks/useTranslate";

interface Editor {
  email: string;
  temporaryPassword: string;
}

const Editors = () => {
  const [editors, setEditors] = useState<Array<Editor>>([]);
  const [baseList, setBaseList] = useState<Array<Editor>>([]);
  const [search, setSearch] = useState("");

  const { errorToast } = useToast();

  const { translateError } = useTranslate();

  const { addEditor, loading } = useGetEditors({
    onSuccess: (res) => {
      setEditors(res);
      setBaseList(res);
    },
    onError: (e) => {
      errorToast(
        translateError(Array.isArray(e.message) ? e.message[0] : e.message)
      );
    },
  });

  const menuOptions: Options[] = [
    {
      label: "Editar",
      event: () => console.log("Editar"),
      icon: <EditOutlined style={{ color: "white" }} />,
    },
    {
      label: "Borrar",
      event: () => console.log("Borrar"),
      icon: <DeleteOutlineSharp style={{ color: theme.palette.error.light }} />,
    },
  ];

  const filterEditors = (search: string) => {
    setEditors(baseList.filter((editor) => editor.email.includes(search)));
  };

  return (
    <Box display="flex" flexDirection="column" gap="24px" height="100%">
      <Box flex={1}>
        <EditorsFilterBar
          title={"Editores"}
          search={setSearch}
          sendSearch={() => filterEditors(search)}
          addEditor={addEditor}
        />
      </Box>
      <Box flex={6} height="1px">
        {loading ? (
          <Grid container direction="column" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : (
          <TableAdmin
            data={editors}
            showDate={false}
            menuOptions={menuOptions}
            showMenu={false}
          />
        )}
      </Box>
    </Box>
  );
};

export default withNavbars(Editors);
