import { AddCategoryDialog } from "components/screens/Categories/AddCategoryDialog";
import { AddSponsorDialog } from "components/screens/Sponsor/AddSponsorDialog";
import { AddEditorsDialog } from "components/screens/Editors/AddEditorDialog";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { closeModal } from "redux/modal.slice";
import ConfirmationModal from "./ConfirmationModal";
import ConfirmationPublished from "./ConfirmationPublished";

const MODALS = {
  ADD_CATEGORY: AddCategoryDialog,
  CONFIRMATION_MODAL: ConfirmationModal,
  ADD_SPONSOR: AddSponsorDialog,
  ADD_EDITOR: AddEditorsDialog,
  CONFIRMATION_PUBLISHED: ConfirmationPublished,
};

const ModalProvider = () => {
  const dispatch = useAppDispatch();
  const modal = useAppSelector((state) => state.modal.modal);

  const onClose = () => {
    dispatch(closeModal());
  };

  if (!modal) return null;

  const ModalToShow = MODALS[modal.type!];

  const props = {
    onClose,
    ...modal?.props,
  };

  return <ModalToShow {...props} />;
};

export default ModalProvider;
