import { CircularProgress, Box, Grid } from "@mui/material";
import NewsFilterBar from "components/screens/HomeNews/NewsFilterBar";
import TableAdmin from "components/common/TableAdmin/TableAdmin";
import withNavbars from "hoc/withNavbar";
import { useState } from "react";
import { useGetNews } from "hooks/useGetNews";
import { Category, useGetCategories } from "hooks/useGetCategories";
import { theme } from "styles/Theme";
import {
  DeleteOutlineSharp,
  EditOutlined,
  Public,
  TrendingUp,
} from "@mui/icons-material";
import { useChangeNewsStatus } from "hooks/useChangeNewsStatus";
import { useNavigate } from "react-router-dom";
import { INews, Options } from "types/News";
import { useDispatch } from "react-redux";
import { ModalType, openModal } from "redux/modal.slice";
import { useToast } from "components/common/Toast/useToast";
import { useFeatureFlags } from "../../common/context/FeatureFlagsContext";
import { useTranslate } from "hooks/useTranslate";
import { getNewsCount, setCurrentPage } from "../../../redux/news.slice";
import { setThingsState } from "redux/thingsToPublish.slice";

const HomeNews = () => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { errorToast } = useToast();
  const featureFlag = useFeatureFlags();

  // State
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");

  const { translateError } = useTranslate();

  // Queries
  const {
    data: news,
    notesFilters: getNewsFilter,
    loading,
    deleteNews,
    nextPageNews,
    prevPageNews,
    lastPage,
  } = useGetNews({
    onError: (e) => {
      errorToast(
        translateError(Array.isArray(e.message) ? e.message[0] : e.message)
      );
    },
  });

  useGetCategories({
    onSuccess: (res) => {
      setCategories(res);
      const count = res.reduce((acc: number, curr: Category) => {
        acc += curr.newsCount || 0;
        return acc;
      }, 0);
      dispatch(getNewsCount(count));
    },
  });

  // const onChangeOptions = async () => {
  //   await getNewsFilter({ s: search, category: category, status: status });
  // };
  //
  // const onDeleteSearch = async () => {
  //   search.length === 0 &&
  //     (await getNewsFilter({ s: search, category: category, status: status }));
  // };

  const { changeStatus } = useChangeNewsStatus({
    onSuccess: () =>
      getNewsFilter({ s: search, category: category, status: status }),
  });

  const menuOptions: Options[] = [
    {
      label: "Editar",
      event: (item: any) => navigate("/editar", { state: item }),
      icon: <EditOutlined style={{ color: "white" }} />,
    },
    {
      label: "Estadistica",
      event: () => console.log("Estadistica"),
      icon: <TrendingUp style={{ color: "white" }} />,
      visible: featureFlag?.statsRoutes,
    },
    {
      label: "Status",
      event: (data: INews) => {
        dispatch(
          openModal({
            type: ModalType.CONFIRMATION_PUBLISHED,
            props: {
              formData: data.newsStatus,
              action: () => (
                changeStatus(data.id, data.newsStatus),
                dispatch(setThingsState({ thingsToPublish: true }))
              ),
            },
          })
        );
      },
      icon: <Public style={{ color: "white" }} />,
    },
    {
      label: "Borrar",
      event: ({ id }: { id: string }) =>
        dispatch(
          openModal({
            type: ModalType.CONFIRMATION_MODAL,
            props: {
              action: () => (
                deleteNews(id),
                dispatch(setThingsState({ thingsToPublish: true }))
              ),
            },
          })
        ),
      icon: <DeleteOutlineSharp style={{ color: theme.palette.error.light }} />,
    },
  ];

  return (
    <Box display="flex" flexDirection="column" gap="24px" height="100%">
      <Box flex={1}>
        <NewsFilterBar
          list={categories}
          title={"Noticias"}
          setStatus={async (val: string) => {
            setStatus(val);
            dispatch(setCurrentPage(0));
            await getNewsFilter({ s: search, category: category, status: val });
          }}
          search={async (val: string) => {
            setSearch(val);
          }}
          setCategory={async (val: string) => {
            setCategory(val);
            dispatch(setCurrentPage(0));
            await getNewsFilter({ s: search, category: val, status: status });
          }}
          sendSearch={async () => {
            dispatch(setCurrentPage(0));
            await getNewsFilter({
              s: search,
              category: category,
              status: status,
            });
          }}
        />
      </Box>
      <Box flex={5} height="1px">
        {loading ? (
          <Grid container direction="column" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : (
          <TableAdmin
            data={news.sort((a, b) => b.SK - a.SK)}
            menuOptions={menuOptions}
            pagination={{
              onNext: nextPageNews,
              onPrevious: prevPageNews,
              lastPage: lastPage,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default withNavbars(HomeNews);
