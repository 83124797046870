import { BASE_URL } from "../utils/http";
import { useState } from "react";
import { setThingsState } from "redux/thingsToPublish.slice";
import { useDispatch } from "react-redux";

export const usePublish = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const publish = () => {
    setLoading(true);
    BASE_URL.post("/publish")
      .then(() => {
        setLoading(false);
        dispatch(setThingsState({ thingsToPublish: false }));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return { publish, loading };
};
