import { RequestOptions } from "./types/Request";
import { useState } from "react";
import { BASE_URL } from "../utils/http";

export const useAdminChangePassword = (passwordChange: RequestOptions) => {
  const [loading, setLoading] = useState(false);

  const changePassword = (currentPassword: string, newPassword: string) => {
    setLoading(true);
    BASE_URL.post(`/admin/change-password`, {
      currentPassword,
      newPassword,
    })
      .then((res) => {
        passwordChange.onSuccess && passwordChange.onSuccess(res);
      })
      .catch((e: any) => {
        passwordChange.onError && passwordChange.onError(e.response.data);
      });
    setLoading(false);
  };

  return { changePassword, loading };
};
