import React from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setCurrentPage } from "../../../redux/news.slice";

interface PaginationProps {
  onPrevious: () => void;
  onNext: () => void;
  lastPage?: boolean;
}

export const NewsPerPage = 10;

const Pagination = (props: PaginationProps) => {
  const count = useAppSelector((state) => state.news.newsCount) || 0;
  const current = useAppSelector((state) => state.news.currentPage) || 0;
  const dispatch = useAppDispatch();

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <IconButton
        onClick={() => {
          props.onPrevious();
          dispatch(setCurrentPage(current - 1));
        }}
        disabled={current === 0}
      >
        <ArrowBackIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          props.onNext();
          dispatch(setCurrentPage(current + 1));
        }}
        disabled={current + 1 === Math.ceil(count / NewsPerPage) || props.lastPage}
      >
        <ArrowForwardIcon />
      </IconButton>
    </div>
  );
};

export default Pagination;
