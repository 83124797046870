import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import withNavbars from "hoc/withNavbar";
import { useGetRoutes } from "hooks/useGetRoutes";
import { useGetStats } from "hooks/useGetStats";
import { useEffect, useState } from "react";
import { periods } from "./consts";
import { StatsTable } from "./StatsTable";

const StatsScreen = () => {
  const [period, setPeriod] = useState<number>(0);
  const [path, setPath] = useState<string>("");

  const { data, getStats } = useGetStats({});

  const { routes } = useGetRoutes();

  useEffect(() => {
    if (path && period) {
      getStats(path, period);
    }
  }, [period, path]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="24px"
      height="100%"
      padding="16px"
      sx={{
        boxSizing: "border-box",
        backgroundColor: "white",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      }}
    >
      <Typography component={"div"} variant={"h5"}>
        <Box sx={{ fontWeight: "bold", m: 1 }}>Estadísticas por Ruta</Box>
      </Typography>
      <Box display="flex" gap="16px">
        <Box sx={{ flex: 2 }} />
        <Autocomplete
          sx={{ flex: 1 }}
          value={path}
          noOptionsText="No hay rutas"
          onChange={(_, val) => setPath(val ?? "")}
          renderInput={(params) => <TextField {...params} label="Ruta" />}
          options={routes.sort((a, b) => a.localeCompare(b)) ?? []}
        />
        <FormControl sx={{ flex: 1 }} fullWidth>
          <InputLabel id="select-label">Período</InputLabel>
          <Select
            labelId="select-label"
            value={period}
            label="Período"
            onChange={(e) => setPeriod(+e.target.value)}
          >
            {periods.map((p) => (
              <MenuItem key={p.value} value={p.value}>
                {p.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {data && <StatsTable data={data} />}
    </Box>
  );
};

export default withNavbars(StatsScreen);
