import { object, string, ref } from "yup";

// MY DATA PAGE
export const initialValuesMyDataPassword = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export const validationSchemaMyDataPassword = object({
  currentPassword: string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d@$&+,:;=?#|'<>.^*()%!-]{8,}$/,
      "Contraseña inválida"
    )
    .required("Campo requerido"),
  newPassword: string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d@$&+,:;=?#|'<>.^*()%!-]{8,}$/,
      "Contraseña inválida"
    )
    .required("Campo requerido"),
  confirmPassword: string()
    .oneOf([ref("newPassword")], "Las contraseñas no coinciden")
    .required("Campo requerido"),
});
