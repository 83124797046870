import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import AddButton from "../../common/AddButton/AddButton";
import { theme } from "styles/Theme";
import { useState } from "react";
import DragAndDrop from "components/common/DragAndDrop/DragAndDrop";
import { validateForm } from "utils/validateForm";
import { object, string } from "yup";
import { useFormik } from "formik";
import { ISponsor } from "types/News";
import { setThingsState } from "redux/thingsToPublish.slice";
import { useDispatch } from "react-redux";

const validationSchema = object({
  name: string().required("Campo requerido"),
  url: string().url("Debe ser una URL").required("Campo requerido"),
});

export const AddSponsorDialog = ({
  onClose,
  formData,
  action,
}: {
  onClose: () => void;
  formData?: ISponsor;
  action?: (s: ISponsor) => void;
}) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const uploadSponsor = (values: ISponsor) => {
    validateForm(
      [
        {
          key: "image",
          value: formik.values.featureImageUrl,
        },
      ],
      {
        onSuccess: () => {
          action && action(values);
          dispatch(setThingsState({ thingsToPublish: true }));
          onClose();
        },
        onError: (e: Record<string, string>) => setErrors(e),
      }
    );
  };

  const formik = useFormik({
    initialValues: {
      name: formData?.name ?? "",
      url: formData?.url ?? "",
      featureImageUrl: formData?.featureImageUrl ?? "",
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: (values) => {
      uploadSponsor({ ...formData, ...values });
    },
  });

  return (
    <Dialog
      open={true}
      onClose={onClose}
      sx={{ borderRadius: "18px !important" }}
      maxWidth={"sm"}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Añade un nuevo patrocinador</DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Nombre"
            type="text"
            fullWidth
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
          />
          <TextField
            size="small"
            margin="dense"
            label="Url"
            type="text"
            fullWidth
            value={formik.values.url}
            onChange={(e) => formik.setFieldValue("url", e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.touched.url && Boolean(formik.errors.url)}
          />
          <DragAndDrop
            value={formik.values.featureImageUrl}
            onLoad={(url) => formik.setFieldValue("featureImageUrl", url)}
            errors={errors}
            fileTypes={["JPG", "PNG", "JPEG", "GIF"]}
          />
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <AddButton
            onClick={onClose}
            style={{
              backgroundColor: theme.palette.grey[200],
              color: theme.palette.grey[900],
              "&:hover": {
                boxShadow: "none",
                backgroundColor: theme.palette.grey[300],
              },
            }}
            size={"medium"}
          >
            Cancelar
          </AddButton>
          <AddButton size={"medium"} type={"submit"}>
            {"Añadir"}
          </AddButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
