import { SideBarOptions } from "types/SideBarTypes";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

interface SideBarItemProps {
  option: SideBarOptions;
}

const SideBarItem = ({ option }: SideBarItemProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <Box>
      <Typography
        color={pathname === option.path ? "primary" : "black"}
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          "&:hover": { cursor: "pointer", filter: "invert(0.3)" },
        }}
        onClick={() => navigate(option.path)}
      >
        {option.icon}
        {option.title}
      </Typography>
    </Box>
  );
};

export default SideBarItem;
