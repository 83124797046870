import { createSlice } from "@reduxjs/toolkit";

interface Login {
  email: string;
  password: string;
}

const initialState: Login = {
  email: '',
  password: ''
}

export const firstLoginSlice = createSlice({
  name: "firstLogin",
  initialState,
  reducers: {
    saveLogin: (state, action) => {
      state.email = action.payload.email;
      state.password = action.payload.password;
    },
    changePass: (state) => {
      state.email = initialState.email
      state.password = initialState.password
    }
  },
});

export const { saveLogin, changePass } = firstLoginSlice.actions;

export default firstLoginSlice.reducer;
