import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useGetCategories } from "hooks/useGetCategories";
import { useEffect, useState } from "react";
import { theme } from "styles/Theme";
import { INews } from "types/News";
import DragAndDrop from "../../common/DragAndDrop/DragAndDrop";

const OptionsSideBar = ({
  newsData,
  setNewsData,
  setPreviewData,
  errors,
}: {
  newsData: INews;
  setNewsData: (key: string, value: any) => void;
  setPreviewData?: (key: string, value: any) => void;
  errors: Record<string, string>;
}) => {
  const [categories, setCategories] = useState<any[]>([]);

  const { loading } = useGetCategories({
    onSuccess: (res) => setCategories(res),
  });

  useEffect(() => {
    setPreviewData &&
      setPreviewData("sectionName", newsData.linkedSection?.sectionName);
  }, []);

  return (
    <Box height="100%" width="25%">
      {loading ? (
        <Box
          height="100%"
          sx={{
            backgroundColor: "white",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box display="flex" justifyContent="center" alignItems="cetner">
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Box
          height="100%"
          sx={{
            backgroundColor: "white",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            height="100%"
            boxSizing="border-box"
            padding={2}
          >
            <Typography fontWeight="bold" fontSize="20px">
              Configuración
            </Typography>
            <Box maxHeight="50%" overflow="auto">
              <Typography fontWeight="bold">Categoría *</Typography>
              <FormControl sx={{ margin: "5px" }}>
                {errors["section"] && (
                  <FormHelperText style={{ color: "red", margin: 0 }}>
                    Campo requerido.
                  </FormHelperText>
                )}
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={(e) => (
                    setNewsData("sectionId", e.target.value),
                    setPreviewData &&
                      setPreviewData("sectionName", e.target.name)
                  )}
                  value={newsData?.sectionId ?? ""}
                >
                  {categories?.map((category) => (
                    <FormControlLabel
                      key={category.id}
                      value={category.id}
                      control={
                        <Radio
                          name={category.sectionName}
                          size="small"
                          sx={{
                            color: errors["section"]
                              ? theme.palette.error.light
                              : theme.palette.grey[600],
                          }}
                        />
                      }
                      label={category.sectionName}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <Typography fontWeight="bold">Estado *</Typography>
              <FormControl sx={{ margin: "5px" }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={newsData?.newsStatus ?? "draft"}
                  onChange={(e) => setNewsData("newsStatus", e.target.value)}
                >
                  <FormControlLabel
                    value="public"
                    control={<Radio size="small" />}
                    label="Público"
                  />
                  <FormControlLabel
                    value="draft"
                    control={<Radio size="small" />}
                    label="Borrador"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <Typography fontWeight="bold">Imagen Destacada *</Typography>
              {errors["image"] && (
                <FormHelperText style={{ color: "red", margin: 0 }}>
                  Campo requerido.
                </FormHelperText>
              )}
              <Box marginTop="16px">
                <DragAndDrop
                  value={newsData?.featureImageUrl}
                  onLoad={(url) => setNewsData("featureImageUrl", url)}
                  errors={errors}
                  fileTypes={["JPG", "PNG", "JPEG"]}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default OptionsSideBar;
