import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import AddButton from "../../common/AddButton/AddButton";
import { theme } from "styles/Theme";
import { useState } from "react";

interface Editor {
  email: string;
  temporaryPassword: string;
}

export const AddEditorsDialog = ({
  onClose,
  formData,
  editEditor,
  addEditor,
}: {
  onClose: () => void;
  formData?: Editor;
  editEditor?: (s: any) => void;
  addEditor?: (s: any) => void;
}) => {
  const [email, setEmail] = useState<string>(formData?.email ?? "");
  const [temporaryPassword, setTemporaryPassword] = useState<string>(
    formData?.temporaryPassword ?? ""
  );

  return (
    <Dialog
      open={true}
      onClose={onClose}
      sx={{ borderRadius: "18px !important" }}
      maxWidth={"sm"}
    >
      <DialogTitle>Añade un nuevo editor</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <TextField
          autoFocus
          margin="dense"
          size="small"
          label="Correo electrónico"
          type="email"
          fullWidth
          defaultValue={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          size="small"
          margin="dense"
          label="Contraseña provisoria"
          type="password"
          fullWidth
          sx={{ borderRadius: 2 }}
          defaultValue={temporaryPassword}
          onChange={(e) => setTemporaryPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <AddButton
          onClick={onClose}
          style={{
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.grey[900],
            "&:hover": {
              boxShadow: "none",
              backgroundColor: theme.palette.grey[300],
            },
          }}
          size={"medium"}
        >
          Cancelar
        </AddButton>
        <AddButton
          onClick={() => {
            formData
              ? editEditor &&
                editEditor({
                  ...formData,
                  email: email,
                  temporaryPassword: temporaryPassword,
                })
              : addEditor &&
                addEditor({
                  email: email,
                  temporaryPassword: temporaryPassword,
                });
            onClose();
          }}
          size={"medium"}
        >
          {formData ? "Guardar" : "Añadir"}
        </AddButton>
      </DialogActions>
    </Dialog>
  );
};
