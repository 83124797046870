import { Add, Search } from "@mui/icons-material";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useAppDispatch } from "redux/hooks";
import { ModalType, openModal } from "redux/modal.slice";
import NavBarButton from "../../common/AddButton/AddButton";

interface Props {
  addEditor: (e: any) => void;
  title: string;
  search?: any;
  sendSearch?: any;
  newSection?: any;
}
const EditorsFilterBar = ({ title, search, sendSearch, addEditor }: Props) => {
  const dispatch = useAppDispatch();
  const handleEnter = (event: any) => {
    if (event.key === "Enter") {
      sendSearch(event.target.value);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: "8px",
        backgroundColor: "white",
        padding: 2,
      }}
    >
      <Grid container direction={"column"}>
        <Grid item container justifyContent={"space-between"}>
          <Grid item>
            <Typography
              component={"div"}
              variant="h5"
              sx={{ fontWeight: "bold", m: 1 }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={"auto"} container alignItems={"center"}>
            <NavBarButton
              color="primary"
              startIcon={<Add />}
              onClick={() =>
                dispatch(
                  openModal({
                    type: ModalType.ADD_EDITOR,
                    props: {
                      addEditor: addEditor,
                    },
                  })
                )
              }
              size={"large"}
            >
              Añadir nuevo editor
            </NavBarButton>
          </Grid>
        </Grid>
        <Grid item container pt={2}>
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            onKeyDown={handleEnter}
          >
            <InputLabel htmlFor="component-outlined">Buscar...</InputLabel>
            <OutlinedInput
              id="component-outlined"
              label={"Buscar ... "}
              onChange={(event) => {
                search(event.target.value);
              }}
              sx={{ borderRadius: 2 }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => {
                      sendSearch(e);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditorsFilterBar;
