import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface LastKeyState {
  lastKey?: string;
  prevLastKey?: string;
  newsCount?: number;
  currentPage?: number;
}

interface LastEvaluatedKeyType {
  PK: string;
  SK: number;
}

const initialState: LastKeyState = {};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    saveLastKey: (state, action: PayloadAction<LastEvaluatedKeyType>) => {
      if (state.prevLastKey) {
        state.prevLastKey = state.lastKey;
      } else {
        state.prevLastKey = undefined;
      }
      state.lastKey = encodeURI(JSON.stringify(action.payload));
    },
    getNewsCount: (state, action: PayloadAction<number>) => {
      state.newsCount = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
});

export const { saveLastKey, getNewsCount, setCurrentPage } = newsSlice.actions;

export default newsSlice.reducer;
