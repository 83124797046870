import React from "react";
import Layout from "./components/common/Router/Layout";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material";
import { theme } from "./styles/Theme";
import ModalProvider from "./components/common/Modals/ModalProvider";
import ToastProvider from "./components/common/Toast/ToastProvider";
import { FeatureFlags } from "components/common/context/FeatureFlagsContext";

const App = () => {
  return (
    <Provider store={store}>
      <FeatureFlags>
        <ThemeProvider theme={theme}>
          <ModalProvider />
          <ToastProvider />
          <Layout />
        </ThemeProvider>
      </FeatureFlags>
    </Provider>
  );
};

export default App;
