import { Box, CircularProgress, Grid } from "@mui/material";
import TableAdmin from "components/common/TableAdmin/TableAdmin";
import withNavbars from "hoc/withNavbar";
import { theme } from "styles/Theme";
import {
  ArrowDownward,
  ArrowUpward,
  DeleteOutlineSharp,
  EditOutlined,
} from "@mui/icons-material";
import SponsorFilterBar from "./SponsorFilterBar";
import { useGetSponsors } from "hooks/useGetSponsors";
import { useAppDispatch } from "redux/hooks";
import { ModalType, openModal } from "redux/modal.slice";
import { ISponsor } from "types/News";
import { useFeatureFlags } from "../../common/context/FeatureFlagsContext";
import { Options } from "types/News";
import { useOrderSponsor } from "hooks/useOrderSponsor";
import { setThingsState } from "redux/thingsToPublish.slice";
import { useState } from "react";

const Sponsor = () => {
  const dispatch = useAppDispatch();
  const [sponsors, setSponsors] = useState<ISponsor[]>([]);
  const { getSponsors, loading, createSponsors, editSponsors, deleteSponsors } =
    useGetSponsors({
      onSuccess: (res) => {
        setSponsors(res);
      },
    });

  const featureFlags = useFeatureFlags();

  const { changeOrderSponsor, loading: loadingOrder } = useOrderSponsor({
    onSuccess: () => {
      getSponsors();
    },
  });

  function compare(a: ISponsor, b: ISponsor): number {
    const orderA = a.sponsorOrder ?? 0;
    const orderB = b.sponsorOrder ?? 0;

    if (orderA < orderB) {
      return -1;
    }
    if (orderA > orderB) {
      return 1;
    }
    return 0;
  }

  const menuOptions: Options[] = [
    {
      label: "Editar",
      event: (item: ISponsor) =>
        dispatch(
          openModal({
            type: ModalType.ADD_SPONSOR,
            props: {
              formData: item,
              action: (data: ISponsor) => {
                editSponsors(data);
                dispatch(setThingsState({ thingsToPublish: true }));
              },
            },
          })
        ),
      icon: <EditOutlined style={{ color: "white" }} />,
    },
    {
      label: "Subir posición",
      event: (item: ISponsor) => {
        if (item.sponsorOrder) {
          item.sponsorOrder >= 1 && changeOrderSponsor(item.sponsorOrder);
          item.sponsorOrder >= 1 &&
            dispatch(setThingsState({ thingsToPublish: true }));
        }
      },
      icon: <ArrowUpward style={{ color: "white" }} />,
    },
    {
      label: "Bajar posición",
      event: (item: ISponsor) => {
        if (item.sponsorOrder != undefined) {
          const order = item.sponsorOrder + 1;
          order !== sponsors.length && changeOrderSponsor(order);
          order !== sponsors.length &&
            dispatch(setThingsState({ thingsToPublish: true }));
        }
      },
      icon: <ArrowDownward style={{ color: "white" }} />,
    },
    {
      label: "Borrar",
      event: ({ id }: ISponsor) =>
        dispatch(
          openModal({
            type: ModalType.CONFIRMATION_MODAL,
            props: {
              action: () => {
                id && deleteSponsors(id);
                id && dispatch(setThingsState({ thingsToPublish: true }));
              },
            },
          })
        ),
      icon: <DeleteOutlineSharp style={{ color: theme.palette.error.light }} />,
    },
  ];

  return (
    <Box display="flex" flexDirection="column" gap="24px" height="100%">
      <Box flex={1}>
        <SponsorFilterBar title="Patrocinadores" addSponsor={createSponsors} />
      </Box>
      <Box flex={10} height="1px">
        {loading || loadingOrder ? (
          <Grid container direction="column" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : (
          <TableAdmin
            showMenu={featureFlags?.sponsorsMenu}
            data={sponsors.sort(compare)}
            showDate={false}
            menuOptions={menuOptions}
          />
        )}
      </Box>
    </Box>
  );
};

export default withNavbars(Sponsor);
