import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useToast } from "components/common/Toast/useToast";
import { useFormik } from "formik";
import withNavbars from "hoc/withNavbar";
import { useAdminChangePassword } from "hooks/useChangePassword";
import { useTranslate } from "hooks/useTranslate";
import { useState } from "react";
import {
  initialValuesMyDataPassword,
  validationSchemaMyDataPassword,
} from "utils/formik";

const MyData = () => {
  const { errorToast, successToast } = useToast();
  const { translateError } = useTranslate();
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false);

  const { changePassword } = useAdminChangePassword({
    onSuccess: () => {
      successToast("Contraseña cambiada exitosamente");
      setDisabledBtn(true);
    },
    onError: (e) => {
      errorToast(
        translateError(Array.isArray(e.message) ? e.message[0] : e.message)
      );
    },
  });

  const formikMyDataPassword = useFormik({
    initialValues: initialValuesMyDataPassword,
    validationSchema: validationSchemaMyDataPassword,
    validateOnBlur: true,
    onSubmit: (values) => {
      if (values.newPassword === values.confirmPassword) {
        changePassword(values.currentPassword, values.newPassword);
      } else {
        errorToast("Las contraseñas no coinciden");
      }
    },
  });

  return (
    <Box>
      <Grid container gap={2}>
        <Grid item container>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              borderRadius: "8px",
              backgroundColor: "white",
              padding: 2,
            }}
          >
            <form
              onSubmit={formikMyDataPassword.handleSubmit}
              style={{ width: "100%" }}
            >
              <Grid container direction={"column"}>
                <Grid item>
                  <Typography component={"div"} variant={"h5"}>
                    <Box sx={{ fontWeight: "bold", m: 1 }}>
                      Cambiar tu contraseña
                    </Box>
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  sx={{ p: 1 }}
                  gap={2}
                  alignContent={"center"}
                >
                  <Grid item xs={12}>
                    <TextField
                      label="Contraseña"
                      type="password"
                      size="small"
                      fullWidth
                      value={formikMyDataPassword.values.currentPassword}
                      onChange={(e) =>
                        formikMyDataPassword.setFieldValue(
                          "currentPassword",
                          e.target.value
                        )
                      }
                      onBlur={formikMyDataPassword.handleBlur}
                      error={
                        formikMyDataPassword.touched.currentPassword &&
                        Boolean(formikMyDataPassword.errors.currentPassword)
                      }
                      helperText={
                        formikMyDataPassword.touched.currentPassword &&
                        formikMyDataPassword.errors.currentPassword
                      }
                      sx={{
                        "& fieldset": {
                          borderRadius: 2,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Nueva Contraseña"
                      type="password"
                      size="small"
                      fullWidth
                      value={formikMyDataPassword.values.newPassword}
                      onChange={(e) =>
                        formikMyDataPassword.setFieldValue(
                          "newPassword",
                          e.target.value
                        )
                      }
                      onBlur={formikMyDataPassword.handleBlur}
                      error={
                        formikMyDataPassword.touched.newPassword &&
                        Boolean(formikMyDataPassword.errors.newPassword)
                      }
                      helperText={
                        formikMyDataPassword.touched.newPassword &&
                        formikMyDataPassword.errors.newPassword
                      }
                      sx={{
                        "& fieldset": {
                          borderRadius: 2,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Confirmar nueva Contraseña"
                      type="password"
                      size="small"
                      fullWidth
                      value={formikMyDataPassword.values.confirmPassword}
                      onChange={(e) =>
                        formikMyDataPassword.setFieldValue(
                          "confirmPassword",
                          e.target.value
                        )
                      }
                      onBlur={formikMyDataPassword.handleBlur}
                      error={
                        formikMyDataPassword.touched.confirmPassword &&
                        Boolean(formikMyDataPassword.errors.confirmPassword)
                      }
                      helperText={
                        formikMyDataPassword.touched.confirmPassword &&
                        formikMyDataPassword.errors.confirmPassword
                      }
                      sx={{
                        "& fieldset": {
                          borderRadius: 2,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item container justifyContent={"center"}>
                    <Button
                      disableRipple
                      disableElevation
                      disabled={disabledBtn}
                      type={"submit"}
                      sx={{
                        boxShadow: "none",
                        textTransform: "none",
                        "@media (max-width: 780px)": {
                          width: "80%",
                        },
                      }}
                      variant="contained"
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withNavbars(MyData);
