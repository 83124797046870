import { useEffect, useState } from "react";
import { BASE_URL } from "../utils/http";

export type UserType = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  company: string;
  role: string;
};
export const useGetUsers = () => {
  const [users, setUsers] = useState<UserType[]>([]);

  const getUsers = () => {
    BASE_URL.get("/stats/users").then((res) => {
      setUsers(res.data.Items);
    });
  };
  useEffect(() => {
    getUsers();
  }, []);

  return { users };
};
