import { useState } from "react";
import { BASE_URL } from "utils/http";
import { RequestOptions } from "./types/Request";

export const useOrderSponsor = (orderSponsor: RequestOptions) => {
  const [loading, setLoading] = useState(false);

  const changeOrderSponsor = (order: number) => {
    setLoading(true);
    BASE_URL.put(`/sponsor/order/${order}`)
      .then((res) => {
        orderSponsor.onSuccess && orderSponsor.onSuccess(res);
      })
      .catch((e: any) => {
        orderSponsor.onError && orderSponsor.onError(e.response.data);
      });
    setLoading(false);
  };

  return { changeOrderSponsor, loading };
};
