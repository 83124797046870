import {
  Box,
  Card,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslate } from "hooks/useTranslate";
import { useUploadNewsImage } from "hooks/useUploadNewsImage";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { theme } from "styles/Theme";
import { useToast } from "../Toast/useToast";

type FileType = "JPG" | "PNG" | "JPEG" | "GIF";

function DragAndDrop({
  value,
  onLoad,
  errors,
  fileTypes,
}: {
  value?: string;
  onLoad: (url: string) => void;
  errors: Record<string, string>;
  fileTypes: FileType[];
}) {
  const [thumbnail, setThumbnail] = useState(value);

  const { errorToast } = useToast();
  const { translateError } = useTranslate();

  const { loadImage, loading } = useUploadNewsImage({
    onSuccess: (res) => {
      setThumbnail(res);
      onLoad(res);
    },
    onError: (e) => {
      errorToast(
        translateError(Array.isArray(e.message) ? e.message[0] : e.message)
      );
    },
  });

  const handleChange = async (file: any) => {
    await loadImage(file.name, file);
  };

  return (
    <FileUploader
      handleChange={handleChange}
      name="file"
      maxSize={25}
      types={fileTypes}
      onSizeError={(e: string) => {
        errorToast(translateError(e));
      }}
      onTypeError={(e: string) => {
        errorToast(translateError(e));
      }}
    >
      <Grid
        item
        container
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={"152px"}
        sx={{
          boxSizing: "border-box",
          border: `1px dashed ${
            errors["image"]
              ? theme.palette.error.light
              : theme.palette.primary.main
          }`,
          borderRadius: "8px",
          width: "100%",
          padding: 1,
          "&:hover": {
            backgroundColor: theme.palette.grey[100],
            cursor: "pointer",
          },
        }}
      >
        {loading ? (
          <Grid container direction="column" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : thumbnail ? (
          <Grid item xs={12} justifyContent={"center"}>
            <Card sx={{ boxShadow: "none" }}>
              <CardMedia
                component="img"
                height="120"
                width="100%"
                image={thumbnail}
                alt="sponsor thumbnail"
                sx={{ objectFit: "contain" }}
              />
            </Card>
          </Grid>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} textAlign={"center"}>
              <Typography component="span" fontSize={"16px"}>
                Clickea para subir tu{" "}
                <Typography
                  component="span"
                  fontSize={"18px"}
                  color={"primary"}
                  fontWeight={"bold"}
                >
                  foto de portada
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
              <Typography component="span" fontSize={"13px"}>
                Tamaño máximo 25MB
              </Typography>
            </Grid>
          </Box>
        )}
      </Grid>
    </FileUploader>
  );
}

export default DragAndDrop;
