import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  isAuthenticated?: boolean;
}

const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem("user-token"),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<string>) => {
      state.isAuthenticated = true;
      localStorage.setItem("user-token", action.payload);
    },

    signOut: (state) => {
      state.isAuthenticated = false;
      localStorage.removeItem("user-token");
    },
  },
});

export const { login, signOut } = authSlice.actions;

export default authSlice.reducer;
