import { Box, TextField, Typography } from "@mui/material";
import { theme } from "styles/Theme";
import { INews } from "types/News";

const TopWriteNewsContainer = ({
  newsData,
  setNewsData,
  errors,
  mode,
}: {
  mode: "add" | "edit";
  newsData?: INews;
  setNewsData: (key: string, value: any) => void;
  errors: Record<string, string>;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        maxHeight: "20vh"
      }}
      borderRadius="8px"
      padding={2}
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Typography fontWeight="bold" fontSize="20px">
        {mode === "add" ? "Añadir una nueva noticia" : "Editar noticia"}
      </Typography>
      <TextField
        error={errors["title"] ? true : false}
        label="Título"
        variant="outlined"
        size="small"
        value={newsData?.title}
        sx={{
          "& fieldset": {
            borderColor: theme.palette.grey[100],
          },
        }}
        onChange={(e) => setNewsData("title", e.target.value)}
        helperText={errors["title"] ? <Typography fontSize='10px'>Campo requerido</Typography> : null}
      />
      <TextField
        error={errors["description"] ? true : false}
        label="Descripción"
        variant="outlined"
        size="small"
        value={newsData?.description}
        inputProps={{ maxLength: 200 }}
        sx={{
          "& fieldset": {
            borderColor: theme.palette.grey[100],
          },
        }}
        onChange={(e) => setNewsData("description", e.target.value)}
        helperText={errors["description"] ? <Typography fontSize='10px'>Campo requerido</Typography> : null}
      />
    </Box>
  );
};

export default TopWriteNewsContainer;
