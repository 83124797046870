import { Grid, Typography } from "@mui/material";
import withNavbars from "hoc/withNavbar";
import { Image } from "components/common/Image";
import LogoImage from "assets/logo.png";
import { Box } from "@mui/system";
import AddButton from "components/common/AddButton/AddButton";
import { useNavigate } from "react-router-dom";

const styles = {
    container: {
        margin: "auto",
        alignContent: "start",
        border: 0,
        backgroundColor: "white",
        borderRadius: 4,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        gap: "30px",
        "@media (max-width: 780px)": {
            width: "80%",
            height: "70%",
        },
    },
};

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <Box sx={styles.container}>
            <Grid container display={"flex"} direction={"column"} justifyItems={"start"} alignItems={"center"} sx={{ gap: 2, pt:8 }}>
                <Grid item container justifyContent={"center"} alignContent={"center"}>
                    <Image
                        src={LogoImage}
                        alt="Noticias Industriales"
                        style={{
                            height: "20%",
                            width: "20%",
                        }}
                    />
                </Grid>
                <Grid item justifyContent={"center"}>
                    <Typography><strong>404.</strong> Se trata de un error </Typography>
                </Grid>
                <Grid item justifyContent={"center"}>
                    <Typography>La <strong>URL</strong> solicitada no se encontró en este servidor.</Typography>
                </Grid>
                <Grid item justifyContent={"center"}>
                    <AddButton
                        onClick={() => { navigate("/") }}
                        size={"medium"}
                    >
                        <Typography fontWeight={"bold"}>Ir a noticia</Typography>
                    </AddButton>
                </Grid>
            </Grid>
        </Box>
    )
};

export default withNavbars(NotFound);