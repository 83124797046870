import { useAppDispatch, useAppSelector } from "redux/hooks";
import { BASE_URL } from "utils/http";
import { signOut } from "redux/auth.slice";
import { useEffect } from "react";

export const useAuth = () => {
  const dispatch = useAppDispatch();

  const authenticated = useAppSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    BASE_URL.post("/auth/token/validate").catch(() => {
      dispatch(signOut());
    });
  }, []);

  return authenticated;
};
