import { Box, CircularProgress, Grid } from "@mui/material";
import TableAdmin from "components/common/TableAdmin/TableAdmin";
import withNavbars from "hoc/withNavbar";
import { Category, useGetCategories } from "hooks/useGetCategories";
import { useState } from "react";
import CategoriesFilterBar from "./CategoriesFilterBar";
import { theme } from "styles/Theme";
import { useDeleteCategories } from "hooks/useDeleteCategories";
import {
  DeleteOutlineSharp,
  EditOutlined,
  TrendingUp,
} from "@mui/icons-material";
import { useAppDispatch } from "redux/hooks";
import { ModalType, openModal } from "redux/modal.slice";
import { useFeatureFlags } from "../../common/context/FeatureFlagsContext";
import { Options } from "types/News";

const Categories = () => {
  // State
  const [categories, setCategories] = useState<Category[]>([]);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState();

  // Hooks
  const dispatch = useAppDispatch();
  const featureFlags = useFeatureFlags();

  // Queries
  const {
    categoriesFilters: getCategoriesFilter,
    editSection,
    addSection,
    deleteCategory,
    loading,
  } = useGetCategories({
    onSuccess: (res) => {
      setCategories(res);
    },
  });

  // Mutations
  // const { deleteCategory } = useDeleteCategories({
  //   onSuccess: (uuid) =>
  //     setCategories(categories.filter((cat) => cat.id !== uuid)),
  // });

  const menuOptions: Options[] = [
    {
      label: "Editar",
      event: () =>
        dispatch(
          openModal({
            type: ModalType.ADD_CATEGORY,
            props: {
              formData: selectedItem,
              editSection,
              categories: categories.map((c) => c.sectionName),
            },
          })
        ),
      icon: <EditOutlined style={{ color: "white" }} />
    },
    {
      label: "Estadistica",
      event: () => console.log("Estadistica"),
      icon: <TrendingUp style={{ color: "white" }} />,
      visible: featureFlags?.stats,
    },
    {
      label: "Borrar",
      event: ({ id }: { id: string }) => {
        dispatch(
          openModal({
            type: ModalType.CONFIRMATION_MODAL,
            props: {
              action: () => deleteCategory(id),
            },
          })
        );
      },
      icon: <DeleteOutlineSharp style={{ color: theme.palette.error.light }} />,
    },
  ];

  return (
    <Box display="flex" flexDirection="column" gap="24px" height="100%">
      <Box flex="1">
        <CategoriesFilterBar
          title={"Categorías"}
          search={setSearch}
          sendSearch={() => getCategoriesFilter({ sectionName: search })}
          addSection={addSection}
          categories={categories.map((c) => c.sectionName)}
        />
      </Box>
      <Box flex="6" height="1px">
        {loading ? (
          <Grid container direction="column" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : (
          <TableAdmin
            selectedItem={setSelectedItem}
            data={categories}
            showDate={false}
            showCount={true}
            menuOptions={menuOptions}
            showMenu={featureFlags?.editCategories}
          />
        )}
      </Box>
    </Box>
  );
};

export default withNavbars(Categories);
