import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import NavBarButton from "components/common/AddButton/AddButton";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ChangeEvent } from "react";

interface Props {
  title: string;
  list: Array<any>;
  setStatus: (e: string) => void;
  search: (val: string) => void;
  setCategory: (e: string) => void;
  sendSearch: () => void;
}

const NewsFilterBar = ({
  title,
  list,
  setStatus,
  search,
  setCategory,
  sendSearch,
}: Props) => {
  const navigate = useNavigate();

  const handleChangeRadio = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus((event.target as HTMLInputElement).value);
  };

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  const handleEnter = (event: any) => {
    if (event.key === "Enter") {
      sendSearch();
    }
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        borderRadius: "8px",
        backgroundColor: "white",
        padding: 2,
      }}
    >
      <Grid container direction={"column"}>
        <Grid item>
          <Typography component={"div"} variant={"h5"}>
            <Box sx={{ fontWeight: "bold", m: 1 }}>{title}</Box>
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ p: 2 }}
          alignContent={"center"}
        >
          <Grid item container xs={5}>
            <Grid item>
              <FormControl>
                <RadioGroup row onChange={handleChangeRadio}>
                  <FormControlLabel
                    value="draft"
                    control={<Radio />}
                    label="Borrador"
                  />
                  <FormControlLabel
                    value="public"
                    control={<Radio />}
                    label="Público"
                  />
                  <FormControlLabel
                    value=""
                    control={<Radio />}
                    label="Todos"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item alignContent={"center"}>
              <FormControl fullWidth sx={{ minWidth: 200 }} size="small">
                <InputLabel>Filtrar por categoria</InputLabel>
                <Select
                  inputProps={{
                    name: "category",
                  }}
                  defaultValue=""
                  onChange={handleChangeSelect}
                  label="Filtrar por categoria"
                >
                  <MenuItem value="">Ninguno</MenuItem>
                  {list.map((item, key) => (
                    <MenuItem key={key} value={item.sectionName}>
                      {item.sectionName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item justifyContent={"end"} alignContent={"center"}>
            <NavBarButton
              startIcon={<Add />}
              onClick={() => navigate("/escribir")}
              size="large"
            >
              Añadir Noticia
            </NavBarButton>
          </Grid>
        </Grid>
        <Grid item container>
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            onKeyDown={handleEnter}
          >
            <InputLabel htmlFor="component-outlined">Buscar...</InputLabel>
            <OutlinedInput
              id="component-outlined"
              label={"Buscar ..."}
              onChange={(event) => {
                search(event.target.value);
              }}
              sx={{ borderRadius: 2 }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={sendSearch}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewsFilterBar;
