import { Box, Button, TextField, Typography } from "@mui/material";
import LogoImage from "assets/logo.png";
import { useAdminLogin } from "hooks/useAdminLogin";
import { useAppDispatch } from "redux/hooks";
import { login } from "redux/auth.slice";
import { Image } from "components/common/Image";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { useFormik } from "formik";
import { saveLogin } from "redux/firstLogin.slice";
import { useToast } from "components/common/Toast/useToast";
import { useTranslate } from "hooks/useTranslate";

const styles = {
  container: {
    margin: "auto",
    border: 0,
    backgroundColor: "white",
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
    "@media (max-width: 780px)": {
      width: "80%",
      height: "70%",
    },
    padding: "24px",
  },
};

const validationSchema = object({
  email: string().email("Debe ser un email").required("Campo requerido"),
  password: string().required("Campo requerido"),
});

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { translateError } = useTranslate();
  const { errorToast } = useToast();
  const { login: loginUser, loading } = useAdminLogin({
    onSuccess: (res) => dispatch(login(res.accessToken)),
    onError: (e) => {
      if (e.message === "A new password is required") {
        dispatch(
          saveLogin({
            email: formik.values.email,
            password: formik.values.password,
          })
        );
        errorToast(
          "Una nueva contraseña es necesaria si es el primer inicio de sesión"
        );
        navigate("/primer-login");
      } else {
        errorToast(
          translateError(Array.isArray(e.message) ? e.message[0] : e.message)
        );
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: async (values) => {
      await loginUser({ email: values.email, password: values.password });
    },
  });

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form
        onSubmit={formik.handleSubmit}
        style={{
          height: "50%",
          width: "25%",
        }}
      >
        <Box sx={styles.container}>
          <Image
            src={LogoImage}
            alt="Noticias Industriales"
            style={{
              height: "auto",
              width: "50%",
            }}
          />
          <Typography>Para continuar, ingrese con su cuenta</Typography>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            width="100%"
            alignItems="center"
          >
            <TextField
              label="Correo Electrónico"
              type="email"
              size="small"
              fullWidth
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{
                "& fieldset": {
                  borderRadius: 2,
                },
              }}
            />
            <TextField
              label="Contraseña"
              type="password"
              size="small"
              fullWidth
              value={formik.values.password}
              onChange={(e) => formik.setFieldValue("password", e.target.value)}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              sx={{
                "& fieldset": {
                  borderRadius: 2,
                },
              }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width="80%"
            gap={1}
            alignItems="center"
          >
            <Button
              disableRipple
              disableElevation
              disabled={loading}
              type={"submit"}
              sx={{
                width: "fit-content",
                boxShadow: "none",
                textTransform: "none",
                "@media (max-width: 780px)": {
                  width: "80%",
                },
              }}
              variant="contained"
            >
              Iniciar Sesión
            </Button>
            {/*<Typography*/}
            {/*  onClick={() => navigate("/recuperar")}*/}
            {/*  sx={{*/}
            {/*    marginY: "12px",*/}
            {/*    textDecoration: "underline",*/}
            {/*    "&:hover": {*/}
            {/*      cursor: "pointer",*/}
            {/*    },*/}
            {/*  }}*/}
            {/*>*/}
            {/*  ¿Olvidaste tu contraseña?*/}
            {/*</Typography>*/}
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Login;
