import { Box, Button, TextField, Typography } from "@mui/material";
import LogoImage from "assets/logo.png";
import { useAppDispatch } from "redux/hooks";
import { Image } from "components/common/Image";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { useFormik } from "formik";
import { useAdminForgotPassword } from "hooks/useForgotPassword";
import { saveEmail } from "redux/forgotPassword.slice";
import { useToast } from "components/common/Toast/useToast";
import { useTranslate } from "hooks/useTranslate";
import { ChevronLeft } from "@mui/icons-material";
import { theme } from "styles/Theme";

const styles = {
  container: {
    margin: "auto",
    border: 0,
    backgroundColor: "white",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
    "@media (max-width: 780px)": {
      width: "80%",
      height: "70%",
    },
    padding: "24px",
  },
};

const validationSchema = object({
  email: string().email("Debe ser un email").required("Campo requerido"),
});

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { successToast, errorToast } = useToast();
  const { translateError } = useTranslate();

  const { forgotPassword, loading } = useAdminForgotPassword({
    onSuccess: () => {
      dispatch(saveEmail({ email: formik.values.email }));
      successToast("Se envió un nuevo correo electrónico");
      navigate("/confirmar");
    },
    onError: (e) => {
      errorToast(
        translateError(Array.isArray(e.message) ? e.message[0] : e.message)
      );
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: async (values) => {
      await forgotPassword({ email: values.email });
    },
  });

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form
        onSubmit={formik.handleSubmit}
        style={{
          height: "30%",
          width: "25%",
        }}
      >
        <Box sx={styles.container}>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Box
              width="100%"
              display="flex"
              alignItems="flex-start"
              justifyContent="flex-start"
              flex={1}
            >
              <ChevronLeft
                sx={{ fontSize: 40, color: theme.palette.grey[500] }}
                onClick={() => navigate("/login")}
                cursor="pointer"
              />
            </Box>
            <Image
              src={LogoImage}
              alt="Noticias Industriales"
              style={{
                flex: "2",
                height: "auto",
                width: "40%",
              }}
            />
            <Box flex={1} />
          </Box>
          <Typography align="center" component="div">
            Para recuperar tu contraseña, ingresá tu correo electrónico
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            justifyItems={"center"}
          >
            <TextField
              label="Correo Electrónico"
              type="email"
              size="small"
              fullWidth
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{
                "& fieldset": {
                  borderRadius: 2,
                },
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Button
              disableRipple
              disableElevation
              disabled={loading}
              type={"submit"}
              size={"small"}
              sx={{
                width: "fit-content",
                mb: 2,
                boxShadow: "none",
                textTransform: "none",
                "@media (max-width: 780px)": {
                  width: "80%",
                },
              }}
              variant="contained"
            >
              <Typography fontWeight={"bold"}>Enviar</Typography>
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ForgotPassword;
