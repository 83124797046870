import "./news.css";

import img from "../../../assets/logo-mini.png";
import moment from "moment";
import "moment/locale/es";
import { useEffect, useState } from "react";
import { CardMedia } from "@mui/material";
import RichTextEditor from "../WriteNews/RichTextEditor";

const mockNews = [
  { title: "Notica recomendada 1" },
  { title: "Notica recomendada 2" },
  { title: "Notica recomendada 3" },
  { title: "Notica recomendada 4" },
];

const NewsPreview = () => {
  moment.locale("es");
  const currentDate = new Date();
  const storedPreviewNews = localStorage.getItem("previewNews");
  const [storePreview, setStorePreview] = useState({
    title: "",
    body: "",
    description: "",
    featureImageUrl: "",
    sectionName: "",
  });

  useEffect(() => {
    storedPreviewNews && setStorePreview(JSON.parse(storedPreviewNews));
  }, [storedPreviewNews]);

  return (
    <body className="previewPage">
      <div className="block">
        <div className="sectionName">{storePreview.sectionName}</div>
        <section>
          <div className={"titleContainer"}>
            <div className="title">{storePreview.title}</div>
            <p>
              {moment(currentDate)
                .format("ll")
                .toUpperCase()
                .replace(/DE/g, "")}
            </p>
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="image_container">
              <CardMedia
                component="img"
                width="100%"
                image={storePreview.featureImageUrl}
                className="image"
              />
              <img src={img} className="logo" alt="logo" />
            </div>
            {/* <div className="date"></div> */}
          </div>
          <div className="bodyWithSponsor">
            <RichTextEditor
              value={storePreview.body}
              setBody={() => null}
              readOnly={true}
              customModule={{ toolbar: false }}
              style={{ width: "100%", border: "none", height: "100%" }}
              styleQuill={{ border: "none" }}
            />
            <div className="sponsor">
              <div className="desktop">
                <p>Sponsor Desktop</p>
              </div>
              <div className="mobile">
                <p>Sponsor Mobile</p>
              </div>
            </div>
          </div>
          <div>
            <div className="subtitle">Noticias recomendadas</div>
            <div className="grid">
              {mockNews.map((item, index) => (
                <div className="gridItem" key={item.title}>
                  <div className="mockBlock">
                    <div className="mockLabel">
                      <p>{item.title}</p>
                    </div>
                  </div>
                  {(index + 1) % 4 !== 0 && (
                    <div className="dividerVerticalDesktop" />
                  )}
                  {(index + 1) % 2 !== 0 && (
                    <div className="dividerVerticalMobile" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </body>
  );
};

export default NewsPreview;
