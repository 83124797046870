import { RequestOptions } from "./types/Request";
import { useState } from "react";
import { BASE_URL } from "../utils/http";
import {INews} from "../types/News";

export const useCreateNews = (requestOptions: RequestOptions) => {
  const [loading, setLoading] = useState(false);
  const addNews = async (news: INews) => {
    const {
      title,
      body,
      sectionId,
      description,
      featureImageUrl,
      newsStatus,
    } = news;
    setLoading(true);
    BASE_URL.post(`/news`, {
      title,
      body,
      sectionId,
      description,
      featureImageUrl,
      newsStatus,
    })
      .then((res) => {
        requestOptions.onSuccess && requestOptions.onSuccess(res.data);
        setLoading(false);
      })
      .catch((e: any) => {
        requestOptions.onError && requestOptions.onError(e.response.data);
        setLoading(false);
      });
  };
  return { addNews, loading };
};
