import { RequestOptions } from "./types/Request";
import { useEffect, useState } from "react";
import { BASE_URL } from "utils/http";

interface AddEditor {
  email: string;
  temporaryPassword: string;
}
interface Editors {
  id?: string;
  mail: string;
}

export const useGetEditors = (optionsEditors: RequestOptions) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Editors[]>([]);

  const addEditor = (editor: AddEditor) => {
    setLoading(true);
    BASE_URL.post("/editor", editor)
      .then((res) => {
        optionsEditors.onSuccess && optionsEditors.onSuccess(getEditors());
      })
      .catch((e: any) => {
        optionsEditors.onError && optionsEditors.onError(e.response.data);
      });
    setLoading(false);
  };

  const getEditors = async () => {
    setLoading(true);
    BASE_URL.get(`/editor`)
      .then((res) => {
        optionsEditors.onSuccess && optionsEditors.onSuccess(res.data.users);
        setLoading(false);
      })
      .catch((e: any) => {
        optionsEditors.onError && optionsEditors.onError(e.response.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    getEditors();
  }, []);
  return { getEditors, addEditor, loading };
};
