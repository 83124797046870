import { RequestOptions } from "./types/Request";
import { useEffect, useState } from "react";
import { BASE_URL } from "utils/http";
import { INews } from "../types/News";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getNewsCount, saveLastKey } from "../redux/news.slice";
import { NewsPerPage } from "../components/common/Pagination/Pagination";

interface FilterNews {
  category: string;
  s: string;
  status: string;
  lastKey?: string;
}

const parseFilter = (filter: FilterNews): string => {
  const pageFilter = { pageSize: NewsPerPage, ...filter };
  return Object.entries(pageFilter)
    .filter((it) => !!it[1])
    .map((it) => `${it[0]}=${it[1]}`)
    .join("&");
};

export const useGetNews = (optionsNews: RequestOptions) => {
  const [data, setData] = useState<INews[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<FilterNews>({
    s: "",
    category: "",
    status: "",
  });
  const [lastPage, setLastPage] = useState<boolean>(false)

  const dispatch = useAppDispatch();
  const lastEvaluatedKey = useAppSelector((state) => state.news.lastKey);
  const prevEvaluatedKey = useAppSelector((state) => state.news.prevLastKey);

  const notesFilters = async (filter: FilterNews) => {
    setFilter(filter);
    setLoading(true);
    BASE_URL.get(`/news?${parseFilter(filter)}`)
      .then((resNewsFilters) => {
        optionsNews.onSuccess &&
          optionsNews.onSuccess(resNewsFilters.data.Items);
        setData(resNewsFilters.data.Items);

        if (resNewsFilters.data.LastEvaluatedKey) {
          dispatch(saveLastKey(resNewsFilters.data.LastEvaluatedKey));
          setLastPage(false)
        } else {
          setLastPage(true)
        }
        setLoading(false);
      })
      .catch((e: any) => {
        optionsNews.onError && optionsNews.onError(e.response.data);
        setLoading(false);
      });
  };

  const nextPageNews = async () =>
    notesFilters({ ...filter, lastKey: lastEvaluatedKey });

  const prevPageNews = async () =>
    notesFilters({ ...filter, lastKey: prevEvaluatedKey });

  const deleteNews = async (uuid: string) => {
    setLoading(true);
    BASE_URL.delete(`/news/${uuid}`)
      .then(() => {
        optionsNews.onSuccess && optionsNews.onSuccess(uuid);
        setData(data.filter((news) => news.id !== uuid));
      })
      .catch((e: any) => {
        optionsNews.onError && optionsNews.onError(e.response.data);
      });
    setLoading(false);
  };

  useEffect(() => {
    notesFilters({ s: "", category: "", status: "" });
  }, []);

  return {
    data,
    notesFilters,
    loading,
    deleteNews,
    nextPageNews,
    prevPageNews,
    lastPage,
  };
};
