import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ThingsState {
  thingsToPublish: boolean;
}

const initialState: ThingsState = {
  thingsToPublish: false,
};

export const thingsToPublishSlice = createSlice({
  name: "thingsState",
  initialState,
  reducers: {
    setThingsState: (state, action) => {
      state.thingsToPublish = action.payload.thingsToPublish;
    },
  },
});

export const { setThingsState } = thingsToPublishSlice.actions;

export default thingsToPublishSlice.reducer;
