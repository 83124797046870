import { Box, Button, TextField, Typography } from "@mui/material";
import LogoImage from "assets/logo.png";
import { useAppSelector } from "redux/hooks";
import { Image } from "components/common/Image";
import { useNavigate } from "react-router-dom";
import { object, string, ref, number } from "yup";
import { useFormik } from "formik";
import { useAdminForgotPassword } from "hooks/useForgotPassword";
import { useToast } from "../../common/Toast/useToast";
import { useTranslate } from "hooks/useTranslate";
import { ChevronLeft } from "@mui/icons-material";
import { theme } from "styles/Theme";

const styles = {
  container: {
    margin: "auto",
    border: 0,
    backgroundColor: "white",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    "@media (max-width: 780px)": {
      width: "80%",
      height: "70%",
    },
    padding: "24px",
  },
};

const validationSchema = object({
  code: number().typeError("Debe ser un número").required("Campo requerido"),
  pass: string()
    .min(8, "Contraseña muy corta")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/,
      "Debe contener al menos una mayúscula y una minúscula"
    )
    .matches(/^(?=.*[!@#$%^&*])/, "Debe conener algún caracter especial")
    .matches(/^(?=.{6,20}$)\D*\d/, "Debe contener algún número")
    .required("Campo requerido"),
  newPass: string()
    .required("Campo requerido")
    .oneOf([ref("pass"), ""], "Las contraseñas no son iguales"),
});

const { translateError } = useTranslate();

const ConfirmationPassword = () => {
  const navigate = useNavigate();
  const email = useAppSelector((state) => state.forgotPassword.email);
  const { successToast, errorToast } = useToast();

  const { forgotPassword, confirmPassword, loading } = useAdminForgotPassword({
    onSuccess: (res) => {
      res?.CodeDeliveryDetails || navigate("/login");
    },
    onError: (e) => {
      errorToast(
        translateError(Array.isArray(e.message) ? e.message[0] : e.message)
      );
    },
  });

  const formik = useFormik({
    initialValues: {
      code: "",
      pass: "",
      newPass: "",
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: async (values) => {
      await confirmPassword({
        email: email,
        confirmationCode: values.code,
        newPassword: values.newPass,
      });
    },
  });

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form
        onSubmit={formik.handleSubmit}
        style={{
          width: "25%",
        }}
      >
        <Box sx={styles.container}>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Box
              width="100%"
              display="flex"
              alignItems="flex-start"
              justifyContent="flex-start"
              flex={1}
            >
              <ChevronLeft
                sx={{ fontSize: 40, color: theme.palette.grey[500] }}
                onClick={() => navigate("/recuperar")}
                cursor="pointer"
              />
            </Box>
            <Image
              src={LogoImage}
              alt="Noticias Industriales"
              style={{
                flex: 2,
                height: "auto",
                width: "40%",
              }}
            />
            <Box flex={1} />
          </Box>
          <Box>
            <Typography align="center">
              Ingresa tu código de confirmacion
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyItems={"center"}
            gap={2}
          >
            <TextField
              label="Código de confirmacion"
              size="small"
              fullWidth
              value={formik.values.code}
              onChange={(e) => formik.setFieldValue("code", e.target.value)}
              onBlur={formik.handleBlur}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
              sx={{
                "& fieldset": {
                  borderRadius: 2,
                },
              }}
            />
            <TextField
              label="Nueva contraseña"
              type="password"
              size="small"
              fullWidth
              value={formik.values.pass}
              onChange={(e) => formik.setFieldValue("pass", e.target.value)}
              onBlur={formik.handleBlur}
              error={formik.touched.pass && Boolean(formik.errors.pass)}
              helperText={formik.touched.pass && formik.errors.pass}
              sx={{
                "& fieldset": {
                  borderRadius: 2,
                },
              }}
            />
            <TextField
              label="Confirmar nueva contraseña"
              type="password"
              size="small"
              fullWidth
              value={formik.values.newPass}
              onChange={(e) => formik.setFieldValue("newPass", e.target.value)}
              onBlur={formik.handleBlur}
              error={formik.touched.newPass && Boolean(formik.errors.newPass)}
              helperText={formik.touched.newPass && formik.errors.newPass}
              sx={{
                "& fieldset": {
                  borderRadius: 2,
                },
              }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent={"center"}
            alignContent={"center"}
            sx={{
              mt: 1,
            }}
          >
            <Button
              disableRipple
              disableElevation
              disabled={loading}
              type={"submit"}
              size={"small"}
              sx={{
                width: "fit-content",
                boxShadow: "none",
                textTransform: "none",
              }}
              variant="contained"
            >
              <Typography fontWeight={"bold"}>Cambiar contraseña</Typography>
            </Button>
          </Box>
          <Typography
            onClick={async () => {
              await forgotPassword({ email: email });
              successToast("Se envió un nuevo correo electrónico");
            }}
            sx={{
              mb: 2,
              textDecoration: "underline",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            Reenviar código de confirmación
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export default ConfirmationPassword;
