import { RequestOptions } from "./types/Request";
import { useState } from "react";
import { BASE_URL } from "../utils/http";
import { INews } from "../types/News";

type RawStats = {
  timestamp: number;
  UserData: {
    given_name: string;
    family_name: string;
    email: string;
    phone_number: string;
    company: string;
    role: string;
  };
  NewsData: Partial<INews> | null;
};

export type Stats = {
  timestamp: string;
  name: string;
  email: string;
  phone_number: string;
  company: string;
  role: string;
  category: string;
};

export const useGetStats = (options: RequestOptions) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Stats[]>([]);

  const getStats = async (path: string, timestamp: number) => {
    setLoading(true);
    BASE_URL.get(encodeURI(`/stats?path=${path}&timestamp=${timestamp}`))
      .then((res) => {
        const data = res.data.Items;
        const stats_data = data.map((s: RawStats) => ({
          timestamp: s.timestamp.toString(),
          name: `${s.UserData.given_name ?? ""} ${
            s.UserData.family_name ?? ""
          }`,
          email: s.UserData.email,
          phone_number: s.UserData.phone_number ?? "",
          company: s.UserData.company ?? "",
          role: s.UserData.role ?? "",
        }));
        setData(stats_data);
        options.onSuccess && options.onSuccess(stats_data);
        setLoading(false);
      })
      .catch((e: any) => {
        options.onError && options.onError(e.response.data);
        setLoading(false);
      });
  };

  return { getStats, data, loading };
};
