import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Image } from "components/common/Image";
import LogoImage from "assets/logo.png";
import { useFormik } from "formik";
import { object, string, ref } from "yup";
import { useAdminLogin } from "hooks/useAdminLogin";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { login } from "redux/auth.slice";
import { useNavigate } from "react-router-dom";
import { changePass } from "redux/firstLogin.slice";
import { useTranslate } from "hooks/useTranslate";
import { useToast } from "components/common/Toast/useToast";

const styles = {
  container: {
    margin: "auto",
    border: 0,
    backgroundColor: "white",
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
    "@media (max-width: 780px)": {
      width: "80%",
      height: "70%",
    },
    padding: "24px",
  },
};

const validationSchema = object({
  password: string()
    .min(8, "Contraseña muy corta")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/,
      "Debe contener al menos una mayúscula y una minúscula"
    )
    .matches(/^(?=.*[!@#$%^&*])/, "Debe conener algún caracter especial")
    .matches(/^(?=.{6,20}$)\D*\d/, "Debe contener algún número")
    .required("Campo requerido"),
  newPassword: string()
    .required("Campo requerido")
    .oneOf([ref("password"), ""], "Las contraseñas no son iguales"),
});

const FirstLogin = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.firstLogin);
  const navigate = useNavigate();

  const { translateError } = useTranslate();
  const { errorToast } = useToast();

  const { firstLogin: setNewPassword, loading } = useAdminLogin({
    onSuccess: (res) => {
      dispatch(login(res.accessToken));
      dispatch(changePass());
      navigate("/");
    },
    onError: (e) =>
      errorToast(
        translateError(Array.isArray(e.message) ? e.message[0] : e.message)
      ),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const send = {
        email: data.email,
        password: data.password,
        newPassword: values.newPassword,
      };
      await setNewPassword(send);
    },
  });

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form
        onSubmit={formik.handleSubmit}
        style={{
          height: "50%",
          width: "25%",
        }}
      >
        <Box sx={styles.container}>
          <Image
            src={LogoImage}
            alt="Noticias Industriales"
            style={{
              height: "auto",
              width: "50%",
            }}
          />
          <Typography>Creá tu nueva contraseña</Typography>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            width="100%"
            alignItems="center"
          >
            <TextField
              label="Nueva contraseña"
              type="password"
              size="small"
              fullWidth
              value={formik.values.password}
              onChange={(e) => formik.setFieldValue("password", e.target.value)}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              sx={{
                "& fieldset": {
                  borderRadius: 2,
                },
              }}
            />
            <TextField
              label="Confirmar nueva contraseña"
              type="password"
              size="small"
              fullWidth
              value={formik.values.newPassword}
              onChange={(e) =>
                formik.setFieldValue("newPassword", e.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.newPassword && Boolean(formik.errors.newPassword)
              }
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
              sx={{
                "& fieldset": {
                  borderRadius: 2,
                },
              }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width="80%"
            gap={1}
            alignItems="center"
          >
            <Button
              disableRipple
              disableElevation
              type={"submit"}
              disabled={loading}
              sx={{
                width: "fit-content",
                marginY: "12px",
                boxShadow: "none",
                textTransform: "none",
              }}
              variant="contained"
            >
              Iniciar Sesión
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default FirstLogin;
