import { RequestOptions } from "./types/Request";
import { useState } from "react";
import { BASE_URL } from "../utils/http";

export type Count = {
  [key: string]: number;
};
export const useGetUserStats = (options: RequestOptions) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Count>();
  const [dataSection, setDataSection] = useState<Count>();

  const getStats = async (user: string, timestamp: number) => {
    setLoading(true);
    BASE_URL.get(
      encodeURI(`/stats/routes?userId=${user}&timestamp=${timestamp}`)
    )
      .then((res) => {
        setData(res.data.Items);
        options.onSuccess && options.onSuccess(res.data.Items);
        setLoading(false);
      })
      .catch((e: any) => {
        options.onError && options.onError(e.response.data);
        setLoading(false);
      });
    BASE_URL.get(
      encodeURI(`/stats/section?userId=${user}&timestamp=${timestamp}`)
    )
      .then((res) => {
        setDataSection(res.data.Items);
        options.onSuccess && options.onSuccess(res.data.Items);
        setLoading(false);
      })
      .catch((e: any) => {
        options.onError && options.onError(e.response.data);
        setLoading(false);
      });
  };

  return { getStats, dataRoutes: data, dataSection, loading };
};
