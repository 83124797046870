import { ArrowDropDown } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { MouseEvent, useState } from "react";
import AddButton from "../AddButton/AddButton";
import { signOut } from "redux/auth.slice";
import { useAppDispatch } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import { useFeatureFlags } from "../context/FeatureFlagsContext";

interface Props {
  userName: string;
}

const MenuButton = ({ userName }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const featureFlags = useFeatureFlags();

  const menuOptions = [
    {
      label: "Cambiar Contraseña",
      event: () => navigate("/mis-datos"),
      visible: featureFlags?.myData,
    },
    {
      label: "Cerrar Sesión",
      event: () => dispatch(signOut()),
    },
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AddButton
        variant="text"
        color="inherit"
        endIcon={
          <ArrowDropDown
            style={{ transform: open ? "rotate(180deg)" : undefined }}
          />
        }
        onClick={handleClick}
        disableRipple={true}
      >
        {userName}
      </AddButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            backgroundColor: "white",
          },
        }}
      >
        {menuOptions &&
          menuOptions.map((option, index) => {
            if (option.visible === false) return;
            else {
              return (
                <MenuItem
                  key={option.label + index}
                  onClick={() => {
                    option.event();
                    handleClose();
                  }}
                >
                  {option.label}
                </MenuItem>
              );
            }
          })}
      </Menu>
    </>
  );
};

export default MenuButton;
