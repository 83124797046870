import { Add, Save } from "@mui/icons-material";
import { AppBar, Toolbar } from "@mui/material";
import NavBarButton from "../AddButton/AddButton";
import MenuButton from "../MenuButton/MenuButton";
import { useNavigate } from "react-router-dom";
import { theme } from "styles/Theme";
import { usePublish } from "hooks/usePublish";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

interface Props {
  userName: string;
}

const NavBar = ({ userName }: Props) => {
  const navigate = useNavigate();
  const { publish, loading } = usePublish();
  const [disableButton, setDisableButton] = useState();

  const { thingsToPublish } = useSelector(
    (state: any) => state.thingsToPublish
  );

  useEffect(() => {
    setDisableButton(thingsToPublish);
  }, [thingsToPublish]);

  return (
    <AppBar
      elevation={0}
      position="static"
      sx={{ background: theme.palette.grey[500], paddingX: 9, height: "68px" }}
    >
      <Toolbar sx={{ justifyContent: "flex-end", gap: 9 }}>
        <NavBarButton
          color={"primary"}
          disabled={!disableButton}
          startIcon={<Save />}
          onClick={publish}
          style={{
            "&:disabled": {
              backgroundColor: "#d9d9d9",
            },
          }}
        >
          Publicar Cambios
        </NavBarButton>
        <NavBarButton
          color="primary"
          startIcon={<Add />}
          onClick={() => navigate("/escribir")}
        >
          Añadir Noticia
        </NavBarButton>
        <MenuButton userName={userName} />
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
