import { Add, Search } from "@mui/icons-material";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import NavBarButton from "../../common/AddButton/AddButton";
import { useAppDispatch } from "redux/hooks";
import { ModalType, openModal } from "redux/modal.slice";
import { useFeatureFlags } from "../../common/context/FeatureFlagsContext";
interface Props {
  addSection: (s: any) => void;
  title: string;
  search: (cat: string) => void;
  sendSearch: () => void;
  newSection?: any;
  categories: string[];
}

const CategoriesFilterBar = ({
  title,
  search,
  sendSearch,
  addSection,
  categories,
}: Props) => {
  //Hooks
  const dispatch = useAppDispatch();
  const featureFlags = useFeatureFlags();

  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      sendSearch && sendSearch();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: "8px",
        backgroundColor: "white",
        padding: 2,
      }}
    >
      <Grid container direction={"column"}>
        <Grid item container justifyContent={"space-between"}>
          <Grid item>
            <Typography
              component={"div"}
              variant="h5"
              sx={{ fontWeight: "bold", m: 1 }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={"auto"} container alignItems={"center"}>
            {featureFlags?.addCategories && (
              <NavBarButton
                color="primary"
                startIcon={<Add />}
                onClick={() =>
                  dispatch(
                    openModal({
                      type: ModalType.ADD_CATEGORY,
                      props: {
                        addSection: addSection,
                        categories: categories,
                      },
                    })
                  )
                }
                size={"large"}
              >
                Añadir nueva categoría
              </NavBarButton>
            )}
          </Grid>
        </Grid>
        <Grid item container pt={2}>
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            onKeyDown={handleEnter}
          >
            <InputLabel htmlFor="component-outlined">Buscar...</InputLabel>
            <OutlinedInput
              id="component-outlined"
              label={"Buscar ..."}
              onChange={(event) => {
                search(event.target.value);
              }}
              sx={{ borderRadius: 2 }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={sendSearch}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CategoriesFilterBar;
