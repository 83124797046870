import { Box, Button } from "@mui/material";
import { CloudUpload, Visibility } from "@mui/icons-material";
import { INews } from "types/News";
import RichTextEditor from "./RichTextEditor";

const RichTextContainer = ({
  newsData,
  uploadNews,
  setNewsData,
  isLoading,
  handlePreview,
}: {
  newsData: INews;
  uploadNews: () => void;
  setNewsData: (key: string, value: any) => void;
  isLoading: boolean;
  handlePreview: () => void;
}) => {
  return (
    <Box
      height="100%"
      sx={{
        backgroundColor: "white",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      }}
      padding={2}
      display="flex"
      flexDirection="column"
    >
      <RichTextEditor
        value={newsData.body}
        setBody={(e) => setNewsData("body", e)}
      />
      <Box
        display="flex"
        gap={2}
        flex={1}
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <Button
          variant="contained"
          startIcon={<Visibility />}
          onClick={() => handlePreview()}
          disableRipple
          disabled={isLoading}
          size="medium"
          sx={{
            textTransform: "none",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
          }}
        >
          Vista previa
        </Button>
        <Button
          variant="contained"
          startIcon={<CloudUpload />}
          onClick={uploadNews}
          disableRipple
          disabled={isLoading}
          size="medium"
          sx={{
            textTransform: "none",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
          }}
        >
          Publicar
        </Button>
      </Box>
    </Box>
  );
};

export default RichTextContainer;
