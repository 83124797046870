import { createSlice } from "@reduxjs/toolkit";

interface ForgotPassword {
  email: string;
}

const initialState: ForgotPassword = {
  email: '',
}

export const forgotPasswordSlice = createSlice({
  name: "firstLogin",
  initialState,
  reducers: {
    saveEmail: (state, action) => {
      state.email = action.payload.email;
    },
    confirmPassword: (state) => {
      state.email = initialState.email
    }
  },
});

export const { saveEmail, confirmPassword } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
