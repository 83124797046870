import { Navigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import ConfirmationPassword from "./ConfirmationPassword";

const ConfirmationPasswordContainer = () => {
  const email = useAppSelector((state) => state.forgotPassword.email);
  return !email ? <Navigate to="/login" replace /> : <ConfirmationPassword />;
};

export default ConfirmationPasswordContainer;
