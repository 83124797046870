import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
  MenuItem,
  LinearProgress,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
} from "@mui/material";
import withNavbars from "hoc/withNavbar";
import { useGetUsers, UserType } from "hooks/useGetUsers";
import { useGetUserStats } from "hooks/useGetUserStats";
import { useEffect, useState } from "react";
import { periods } from "./consts";
import { BarChart } from "./BarChart";
import { PieChart } from "./PieChart";
import {
  Business,
  Engineering,
  Inbox,
  LocalPhone,
  Person,
} from "@mui/icons-material";

const StatsUsersScreen = () => {
  const [period, setPeriod] = useState<number>(periods[0].value);
  const [user, setUser] = useState<UserType | null>(null);

  const { loading, dataRoutes, dataSection, getStats } = useGetUserStats({});

  const { users } = useGetUsers();

  useEffect(() => {
    if (user && period) {
      getStats(user.id, period);
    }
  }, [period, user]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="24px"
      height="100%"
      padding="16px"
      sx={{
        boxSizing: "border-box",
        backgroundColor: "white",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      }}
    >
      <Typography component={"div"} variant={"h5"}>
        <Box sx={{ fontWeight: "bold", m: 1 }}>Estadísticas por Usuario</Box>
      </Typography>
      <Box display="flex" gap="16px">
        <Box sx={{ flex: 2 }} />
        <Autocomplete
          sx={{ flex: 1 }}
          value={user ?? null}
          noOptionsText="No hay usuarios"
          onChange={(_, val) => setUser(val)}
          renderInput={(params) => <TextField {...params} label="Usuario" />}
          renderOption={(params, option) => (
            <li {...params} key={option.id}>
              {option.name}
            </li>
          )}
          getOptionLabel={(option) => option.name}
          options={users ?? []}
        />
        <FormControl sx={{ flex: 1 }} fullWidth>
          <InputLabel id="select-label">Período</InputLabel>
          <Select
            labelId="select-label"
            value={period}
            label="Período"
            onChange={(e) => setPeriod(+e.target.value)}
          >
            {periods.map((p) => (
              <MenuItem key={p.value} value={p.value}>
                {p.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {loading ? (
        <Box height="100%" width="100%">
          <LinearProgress />
        </Box>
      ) : (
        dataRoutes &&
        dataSection && (
          <Box height="100%" display="flex" flexDirection="row">
            <Box flex={7}>
              <BarChart dataRoutes={dataRoutes} />
            </Box>
            <Box flex={3} display="flex" flexDirection="column">
              <Box flex={1} width="100%" padding="16px">
                <List
                  sx={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    height: "100%",
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <Person />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "subtitle1",
                        style: {
                          width: "200px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    >
                      {user?.name ?? "-"}
                    </ListItemText>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <Inbox />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "subtitle1",
                        style: {
                          width: "200px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    >
                      {user?.email ?? "-"}
                    </ListItemText>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <LocalPhone />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "subtitle1",
                        style: {
                          width: "200px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    >
                      {user?.phoneNumber ?? "-"}
                    </ListItemText>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <Business />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "subtitle1",
                        style: {
                          width: "200px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    >
                      {user?.company ?? "-"}
                    </ListItemText>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <Engineering />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "subtitle1",
                        style: {
                          width: "200px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    >
                      {user?.role ?? "-"}
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>
              <Box flex={1}>
                <PieChart dataSection={dataSection} />
              </Box>
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

export default withNavbars(StatsUsersScreen);
