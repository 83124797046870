import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";
import AddButton from "../../common/AddButton/AddButton";
import { theme } from "styles/Theme";
import { boolean, object, string } from "yup";
import { useFormik } from "formik";

interface Section {
  id: string;
  sectionName: string;
  description: string;
  hidden: boolean;
}

export const AddCategoryDialog = ({
  onClose,
  formData,
  editSection,
  addSection,
  categories,
}: {
  onClose: () => void;
  formData?: Section;
  editSection?: (s: any) => void;
  addSection?: (s: any) => void;
  categories?: string[];
}) => {
  const validationSchema = object({
    name: string()
      .notOneOf(categories?.filter((cat) => cat !== formData?.sectionName) ?? [], "Nombre ya existente")
      .required("Campo requerido"),
    description: string().required("Campo requerido"),
    hidden: boolean(),
  });

  const formik = useFormik({
    initialValues: {
      name: formData?.sectionName ?? "",
      description: formData?.description ?? "",
      hidden: formData?.hidden ?? true,
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: (values) => {
      formData
        ? editSection &&
        editSection({
          ...formData,
          sectionName: values.name,
          description: values.description,
          hidden: values.hidden,
        })
        : addSection &&
        addSection({
          sectionName: values.name,
          description: values.description,
          hidden: values.hidden,
        });
      onClose();
    },
  });

  return (
    <Dialog
      open={true}
      onClose={onClose}
      sx={{ borderRadius: "18px !important" }}
      maxWidth={"sm"}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Añade una nueva categoría</DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Categoría"
            type="text"
            fullWidth
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            size="small"
            margin="dense"
            label="Descripción"
            type="text"
            fullWidth
            sx={{ borderRadius: 2 }}
            value={formik.values.description}
            onChange={(e) =>
              formik.setFieldValue("description", e.target.value)
            }
            onBlur={formik.handleBlur}
            error={formik.touched.description && !!formik.errors.description}
            helperText={formik.touched.description && formik.errors.description}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.hidden}
                onChange={(e) =>
                  formik.setFieldValue("hidden", e.target.checked)
                }
              />
            }
            label="Principal"
          />
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <AddButton
            onClick={onClose}
            style={{
              backgroundColor: theme.palette.grey[200],
              color: theme.palette.grey[900],
              "&:hover": {
                boxShadow: "none",
                backgroundColor: theme.palette.grey[300],
              },
            }}
            size={"medium"}
          >
            Cancelar
          </AddButton>
          <AddButton type="submit" size={"medium"}>
            {formData ? "Guardar" : "Añadir"}
          </AddButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
