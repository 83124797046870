import Card from "@mui/material/Card";
import moment from "moment";
import {
  Typography,
  Chip,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  CardContent,
  capitalize,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box } from "@mui/system";
import { MouseEvent, useState } from "react";
import { theme } from "styles/Theme";
import { Edit, Public } from "@mui/icons-material";
import { Options } from "types/News";
import { useTranslate } from "hooks/useTranslate";

interface Props {
  id: string;
  data: any;
  title: string;
  newsStatus?: string;
  date: string;
  section: any;
  count?: number;
  showDate?: boolean;
  showMenu?: boolean;
  showCount?: boolean;
  onClickOptions?: () => void;
  menuOptions: Options[];
}

const CardRow = ({
  data,
  title,
  newsStatus,
  date,
  section,
  count,
  showDate = true,
  showMenu = true,
  showCount = false,
  onClickOptions,
  menuOptions,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const { translateChip } = useTranslate();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClickOptions && onClickOptions();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card
      sx={{
        borderRadius: "8px",
        border: "1px solid",
        borderColor: theme.palette.grey[100],
        "&:hover": { borderColor: "primary.main" },
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          px: 2,
          pt: 2,
          pb: "16px !important",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          flex="10"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container display="flex" alignItems="center">
            <Grid
              item
              container
              direction={"column"}
              xs
              display="flex"
              justifyContent="center"
            >
              <Typography variant="h6" color={"text.primary"}>
                {title}
              </Typography>
            </Grid>
          </Grid>
          {showDate && (
            <Grid container>
              <Grid
                item
                container
                direction={"row"}
                alignItems={"center"}
                sx={{ mt: 2 }}
              >
                <Grid item>
                  <Typography color="text.secondary">
                    {moment(date).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
                <Grid item sx={{ ml: 1.6 }}>
                  <Box sx={{ fontWeight: "bold" }}>
                    <Typography color="black">
                      {section?.sectionName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sx={{ ml: 1.6 }}>
                  <Chip
                    icon={newsStatus === "public" ? <Public /> : <Edit />}
                    size="small"
                    label={capitalize(translateChip(newsStatus ?? ""))}
                    color="primary"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
        <Box
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid
            item
            container
            xs={"auto"}
            direction={"row"}
            alignContent={"center"}
            gap={2}
          >
            {showCount && (
              <Grid item alignSelf={"center"}>
                <Chip
                  label={count}
                  size={"small"}
                  variant="outlined"
                  sx={{
                    borderRadius: "4px",
                    borderColor: theme.palette.grey[100],
                    "& .MuiChip-label": {
                      color: theme.palette.grey[700],
                      fontWeight: "bold",
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
          {showMenu && (
            <Grid item>
              <IconButton
                disableRipple
                onClick={handleClick}
                sx={{
                  ":hover": {
                    bgcolor: theme.palette.grey[100],
                  },
                }}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    backgroundColor: theme.palette.grey[500],
                    borderRadius: "8px",
                    padding: "8px",
                    alignItems: "flex-start",
                  },
                }}
              >
                {menuOptions.map((item, index) => {
                  if (item?.visible === false) return null;
                  return (
                    <MenuItem
                      key={index}
                      disableRipple
                      onClick={() => {
                        item.event(data);
                        handleClose();
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <Typography color={"white"}>
                        {item.label === "Status"
                          ? newsStatus === "draft"
                            ? "Cambiar a Público"
                            : "Cambiar a Borrador"
                          : item.label}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Menu>
            </Grid>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardRow;
