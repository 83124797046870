import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      light: "#A5CCF4",
      main: "#5D9FF8",
      dark: "#428AD2",
      contrastText: "#FFFFFF",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#E57472",
      main: "#E03C39",
      // dark: will be calculated from palette.secondary.main,
    },
    error: {
      main: "#E03C39",
      light: "#E57472",
    },
    grey: {
      100: "#EDEDED", //bgLines
      200: "#D9D9D9", //boton
      300: "#ABAAAA", //text 2
      400: "#787777", //text
      500: "#545454", //logo
      600: "#7c7c7c",
    },
  },
  typography: {
    fontFamily: "Helvetica; sans-serif",
    fontSize: 12,
  },
});
