import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Count } from "../../../hooks/useGetUserStats";
import { theme } from "../../../styles/Theme";
import { Box, Typography } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieChart = ({ dataSection }: { dataSection: Count }) => {
  const data = {
    labels: Object.keys(dataSection),
    datasets: [
      {
        data: Object.values(dataSection),
        backgroundColor: [
          theme.palette.primary.light,
          theme.palette.primary.main,
          theme.palette.primary.dark,
          theme.palette.secondary.main,
          theme.palette.secondary.light,
          "rgba(255, 159, 64, 0.2)",
        ],
      },
    ],
  };

  return (
    <Box height="100%">
      {Object.keys(dataSection).length ? (
        <Doughnut
          data={data}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
      ) : (
        <Typography>No hay datos del usuario</Typography>
      )}
    </Box>
  );
};
