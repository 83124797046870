import { Button, SxProps } from "@mui/material";
import { MouseEvent, ReactNode } from "react";

interface Props {
  startIcon?: ReactNode;
  variant?: "contained" | "text";
  color?: "error" | "primary" | "inherit";
  size?: "small" | "medium" | "large";
  endIcon?: ReactNode;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disableRipple?: boolean;
  children: ReactNode;
  style?: SxProps;
  type?: "submit";
  disabled?: boolean;
}

const AddButton = ({
  size = "small",
  color = "primary",
  variant = "contained",
  startIcon,
  endIcon,
  onClick,
  children, disabled,
  style,
  type
}: Props) => {
  return (
    <Button
      variant={variant}
      disabled={disabled}
      color={color}
      startIcon={startIcon}
      disableRipple
      endIcon={endIcon}
      size={size}
      type={type}
      sx={{
        textTransform: "none",
        boxShadow: "none",
        fontWeight: "bold",
        fontSize: "14px",
        "&:hover": {
          boxShadow: "none",
        },
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default AddButton;
