import { useEffect } from "react";
import { useQuill } from "react-quilljs";
import BlotFormatter from "quill-blot-formatter";
import "quill/dist/quill.snow.css";
import "./QuillStyles.css";

const modules = {
  toolbar: [
    [{ header: [false, 3, 2] }],
    ["bold", "italic", "underline", "strike"],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
  ],
};

const RichTextEditor = ({
  value,
  setBody,
  readOnly = false,
  customModule = modules,
  style,
  styleQuill,
}: {
  value?: string;
  setBody: (s: string) => void;
  readOnly?: boolean;
  customModule?: any;
  style?: any;
  styleQuill?: any;
}) => {
  const { quill, quillRef, Quill } = useQuill({
    modules: { blotFormatter: {}, ...customModule },
    readOnly: readOnly,
  });

  if (Quill && !quill) {
    Quill.register("modules/blotFormatter", BlotFormatter);
  }

  useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        setBody(quill.root.innerHTML);
      });
      quill.root.setAttribute("spellcheck", "false");
    }
  }, [quill, Quill]);

  useEffect(() => {
    if (quill) {
      value && quill.clipboard.dangerouslyPasteHTML(value);
    }
  }, [quill]);

  return (
    <div style={{ height: "52vh", ...style }}>
      <div ref={quillRef} style={{ ...styleQuill }} />
    </div>
  );
};

export default RichTextEditor;
