import axios from "axios";
import { RequestOptions } from "./types/Request";
import { useState } from "react";
import { BASE_URL } from "utils/http";

interface LoginData {
  email: string;
  password: string;
}

interface FirstLogin {
  email: string;
  password: string;
  newPassword: string;
}

export const useAdminLogin = (options: RequestOptions) => {
  const [loading, setLoading] = useState(false);
  const login = async (loginData: LoginData) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/admin/login`,
        loginData
      );
      options.onSuccess && options.onSuccess(res.data);
    } catch (e: any) {
      options.onError && options.onError(e.response?.data ?? e.message);
    }
    setLoading(false);
  };

  const firstLogin = async (firstLogin: FirstLogin) => {
    setLoading(true);
    try {
      BASE_URL.post("/admin/login", firstLogin)
        .then((res) => {
          options.onSuccess && options.onSuccess(res.data);
        })
        .catch((e: any) => {
          options.onError && options.onError(e.response.data);
        });
    } catch (e: any) {
      options.onError && options.onError(e.response.data);
    }
    setLoading(false);
  };

  return { login, loading, firstLogin };
};
