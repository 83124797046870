interface FormItem {
  key: string;
  value?: string;
}

interface Options {
  onSuccess: () => void;
  onError: (e: Record<string, string>) => void;
}

export const validateForm = (formItems: FormItem[], options: Options) => {
  const errors = Object.fromEntries(
    formItems
      .map((item) => (!item.value ? [item.key, `${item.key} is required`] : []))
      .filter((a) => a.length > 0)
  );
  if (Object.keys(errors).length === 0) {
    options.onSuccess();
  } else {
    options.onError(errors);
  }
};
