import withNavbars from "hoc/withNavbar";
import { Box } from "@mui/material";
import TopWriteNewsContainer from "./TopWriteNewsContainer";
import RichTextContainer from "./RichTextContainer";
import OptionsSideBar from "./OptionsSideBar";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { validateForm } from "utils/validateForm";
import { INews } from "types/News";
import { useEditNews } from "hooks/useEditNews";
import { useDispatch } from "react-redux";
import { setThingsState } from "redux/thingsToPublish.slice";

const EditNews = () => {
  const { state: newsData } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<INews>({
    ...newsData,
    sectionId: newsData?.linkedSection?.id,
  });
  const [previewNew, setPreviewNew] = useState<INews>({
    SK: 0,
    sectionName: "",
  });
  const [errors, setErrors] = useState({});

  const { editNews, loading } = useEditNews({
    onSuccess: () => navigate("/"),
  });

  const setPreviewData = (key: string, value: any) => {
    setPreviewNew((state: INews) => ({
      ...state,
      [key]: value,
    }));
  };

  const handlePreview = () => {
    validateForm(
      [
        {
          key: "section",
          value: formData?.sectionId,
        },
        {
          key: "title",
          value: formData?.title,
        },
        {
          key: "description",
          value: formData?.description,
        },
        {
          key: "image",
          value: formData?.featureImageUrl,
        },
      ],
      {
        onSuccess: () => {
          localStorage.setItem(
            "previewNews",
            JSON.stringify({
              title: formData.title,
              body: formData.body,
              description: formData.description,
              featureImageUrl: formData.featureImageUrl,
              sectionName: previewNew.sectionName,
            })
          );
          window.open("vista-previa", "_blank");
        },
        onError: (e: Record<string, string>) => setErrors(e),
      }
    );
  };

  const uploadNews = () => {
    validateForm(
      [
        {
          key: "section",
          value: formData?.sectionId,
        },
        {
          key: "title",
          value: formData?.title,
        },
        {
          key: "description",
          value: formData?.description,
        },
        {
          key: "image",
          value: formData?.featureImageUrl,
        },
      ],
      {
        onSuccess: async () =>
          await (editNews(formData),
          dispatch(setThingsState({ thingsToPublish: true }))),
        onError: (e: Record<string, string>) => setErrors(e),
      }
    );
  };

  const setNewsData = (key: string, value: any) => {
    setFormData((state: INews) => ({
      ...state,
      [key]: value,
    }));
  };

  return (
    <Box height="100%" width="100%" display="flex" gap="24px">
      <Box
        display="flex"
        flexDirection="column"
        gap="24px"
        height="100%"
        width="100%"
      >
        <TopWriteNewsContainer
          mode="edit"
          newsData={formData}
          setNewsData={setNewsData}
          errors={errors}
        />
        <RichTextContainer
          newsData={formData}
          uploadNews={() => uploadNews()}
          setNewsData={setNewsData}
          isLoading={loading}
          handlePreview={handlePreview}
        />
      </Box>
      <OptionsSideBar
        newsData={formData}
        setNewsData={setNewsData}
        setPreviewData={setPreviewData}
        errors={errors}
      />
    </Box>
  );
};

export default withNavbars(EditNews);
